import { getTheme, IButtonStyles, IStackItemStyles, mergeStyles } from '@fluentui/react';

const palette = getTheme().palette;

const authContainer = mergeStyles({
  marginTop: '10%',
  textAlign: 'left',
  '@media only screen and (max-width: 600px)': {
    marginTop: '40%'
  }
});

const authFormStackStyles: IStackItemStyles = {
  root: {
    width: '20vw',
    backgroundColor: palette.white,
    zIndex: 10,
    '@media only screen and (max-width: 600px)': {
      width: '60vw'
    }
  }
};

const logoImage = mergeStyles({
  maxWidth: '12rem',
  marginBottom: '1.125rem',
  '@media only screen and (max-width: 600px)': {
    maxWidth: '7rem',
    marginBottom: '2.025rem'
  }
});

const logoImageInFooter = mergeStyles({
  position: 'fixed',
  bottom: '0',
  width: '20vw',
  marginBottom: '1rem',
  '@media only screen and (max-width: 600px)': {
    width: '60vw'
  }
});

const submitButtonStyles: IButtonStyles = {
  root: {
    height: '2rem',
    marginTop: '0.625rem',
    backgroundColor: palette.black,
    color: palette.white
  }
};

export { authContainer, authFormStackStyles, logoImage, logoImageInFooter, submitButtonStyles };
