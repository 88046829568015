import { mergeStyles, Stack } from '@fluentui/react';
import PCALogoGIF from '../../assets/PCA-Logo-GIF.gif';
import { ReactComponent as QRCodeSVG } from '../../assets/QR-Code.svg';
import { Settings } from '../../types/settings';
import { appSettings } from '../../appSettings';

type LandingScreenProps = {
  settings?: Settings;
};

export default (props: LandingScreenProps): JSX.Element => {
  return (
    <>
      <Stack verticalAlign="end" className={projectorLandingContainer}>
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className={contentContainer}>
          <img src={PCALogoGIF} className={pcaLogo} />
          <div className={connectionInfo}>
            {/* <span style={{ fontFamily: 'Harbourfront Body Bold' }}>WiFi Access</span>
            <p>{`WiFi Network: ${props.settings?.wifiSSID ?? 'Configuring...'}`}</p>
            <p>{`WiFi Password: ${props.settings?.wifiPassword ?? 'Configuring...'}`}</p> */}
            <p>{`Scan the QR code to access the app`}</p>
            <p>{`or visit: `}</p>
            <i>{`${appSettings.host.artAuctionClientHost}`}</i>
          </div>
          {/* <QRCodeSVG className={qrCode} /> */}
        </Stack>
        {/* <Stack verticalAlign="end" horizontalAlign="end" className={footerContainer}>
          <div style={{ marginRight: '5rem' }}>Powered by</div>
          <HarbourfrontLogoSVG className={logoInFooter} />
        </Stack> */}
      </Stack>
    </>
  );
};

const connectionInfo = mergeStyles({
  position: 'relative',
  // top: '-1.8%',
  // left: '-9%',
  // marginTop: '-13%',
  fontSize: '3rem',
  // fontWeight: '900',
  textAlign: 'left',
  selectors: {
    '& p': {
      margin: '0.3rem 0'
    }
  },
  '@media only screen and (max-width: 1410px)': {
    top: '-1.8%',
    left: '-9%',
    fontSize: '1.1rem',
    fontWeight: '700',
    selectors: {
      '& p:last-child': {
        marginTop: '6.3%'
      }
    }
  }
});

const contentContainer = mergeStyles({
  marginBottom: '8%'
});

const projectorLandingContainer = mergeStyles({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: '0 3rem 1rem'
});

const logoInFooter = mergeStyles({
  width: '15.625rem',
  marginBottom: '1rem'
});

const footerContainer = mergeStyles({
  width: '100%',
  bottom: '1rem',
  display: 'flex',
  justifyContent: 'center'
});

const pcaLogo = mergeStyles({
  height: '34.2rem'
});

const qrCode = mergeStyles({
  height: '40rem'
});
