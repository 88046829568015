import { getTheme, Icon, mergeStyles, Stack } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { appSettings } from '../../appSettings';
import LotCard from '../../components/bidder/LotCard';
import { getAWinList } from '../../services/bidder.service';
import { BidderCookie } from '../../types/bidderCookie';

const palette = getTheme().palette;

/* eslint-disable @typescript-eslint/no-explicit-any */
export default (): JSX.Element => {
  const [bidderName, setBidderName] = useState<string>();
  const [lotCards, setLotCards] = useState<(JSX.Element | undefined)[]>([]);
  const [cookies] = useCookies([appSettings.bidder.userInfoCookieName]);
  const bidderId = (cookies[appSettings.bidder.userInfoCookieName] as BidderCookie)?.id;
  const bidderToken = (cookies[appSettings.bidder.userInfoCookieName] as BidderCookie)?.token;

  useEffect(() => {
    (async () => {
      const winListByBidderId = await getAWinList(bidderToken, bidderId);
      setBidderName(winListByBidderId.name);
      const allLots = winListByBidderId.lots;
      const lotListInCardFormat = allLots
        .sort((lot1: any, lot2: any) => {
          return Number(lot1.lotIndex) - Number(lot2.lotIndex);
        })
        .map((lot: any) => {
          if (!lot.lotIndex || !lot.title) return;
          const lotCardProps = { isDefaultStyle: true, lotId: lot['_id'], lotIndex: lot.lotIndex, lotTitle: lot.title };
          return <LotCard {...lotCardProps} />;
        });
      setLotCards(lotListInCardFormat);
    })();
  }, []);

  return (
    <Stack verticalAlign="space-around" horizontalAlign="start" className={lotWinListContainer}>
      <h2 style={{ fontFamily: 'Harbourfront Body Bold', marginBottom: 0 }}>{bidderName}</h2>
      <p style={{ fontSize: '0.7rem', margin: '0.4rem' }}>以下是你成功競投的拍品.</p>
      <p style={{ fontSize: '0.7rem', margin: '0.4rem' }}>This page will display lots you have successfully won.</p>
      <div style={{ width: '100%', margin: '0.5rem 0 0.4rem 0', borderBottom: '0.0625rem solid grey' }}></div>
      {lotCards && lotCards.length ? (
        lotCards
      ) : (
        <Stack horizontalAlign="center" verticalAlign="center" className={emptyWinListContainer}>
          <Icon iconName="Photo2" style={{ fontSize: '5rem', color: palette.neutralSecondary, opacity: '0.5' }} />
          <p style={{ fontSize: '0.9rem', fontWeight: '500', opacity: '0.8', margin: '0.4rem' }}>沒有成功競投拍品</p>
          <p style={{ fontSize: '0.9rem', fontWeight: '500', opacity: '0.8', margin: '0.4rem' }}>
            No artworks won by you.
          </p>
        </Stack>
      )}
    </Stack>
  );
};

const lotWinListContainer = mergeStyles({
  padding: '1.25rem',
  textAlign: 'left'
});

const emptyWinListContainer = mergeStyles({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '3.8vw'
});
