import { getTheme, Icon, mergeStyles, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { appSettings } from '../../appSettings';
import LotCard from '../../components/bidder/LotCard';
import { getAllLots } from '../../services/lot.service';
import { BidderCookie } from '../../types/bidderCookie';
import { useNavigate } from 'react-router-dom';

const palette = getTheme().palette;

/* eslint-disable @typescript-eslint/no-explicit-any */
export default (): JSX.Element => {
  const [lotCards, setLotCards] = useState<(JSX.Element | undefined)[]>([]);
  const [displayLots, setDisplayLots] = useState(false);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [cookies] = useCookies([appSettings.bidder.userInfoCookieName]);
  const bidderToken = (cookies[appSettings.bidder.userInfoCookieName] as BidderCookie)?.token;
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const allLots = await getAllLots(bidderToken);
      // make sure all lots have a unique key prop
      allLots.forEach((lot: any, index: number) => {
        lot.key = index;
      });

      // if any lot has status bidding navigate to lot bid page
      const lotWithBiddingStatus = allLots.find((lot: any) => lot.status === 'bidding');
      if (lotWithBiddingStatus) {
        navigate(`/bidder/lots/${lotWithBiddingStatus['_id']}/bid`);
      } else {
        const lotListInCardFormat = allLots
          .sort((lot1: any, lot2: any) => {
            return Number(lot1.lotIndex) - Number(lot2.lotIndex);
          })
          .map((lot: any) => {
            if (!lot.lotIndex || !lot.title) return;
            const lotCardProps = {
              lotId: lot['_id'],
              lotIndex: lot.lotIndex,
              lotTitle: lot.title,
              lotStatus: lot.status,
              key: lot.lotIndex
            };
            return <LotCard {...lotCardProps} />;
          });
        setLotCards(lotListInCardFormat);
        setDisplayLots(true);
      }
    })();
  }, [refreshKey]);

  return (
    <>
      <Stack className={lotListContainer}>
        {!displayLots ? (
          <Spinner size={SpinnerSize.large} label="Preparing artworks for you..." className={emptyWinListContainer} />
        ) : lotCards && lotCards.length ? (
          lotCards
        ) : (
          <Stack horizontalAlign="center" verticalAlign="center" className={emptyWinListContainer}>
            <Icon
              iconName="ImageSearch"
              style={{ fontSize: '5rem', color: palette.neutralSecondary, opacity: '0.5' }}
            />
            <p style={{ fontSize: '0.9rem', fontWeight: '800', marginBottom: 0 }}>Oops...Nothing to show yet.</p>
            <p style={{ fontSize: '0.7rem', fontWeight: '500', marginTop: '0.3rem', opacity: '0.8' }}>
              The registrar will add artworks soon...
            </p>
          </Stack>
        )}
      </Stack>
    </>
  );
};

const lotListContainer = mergeStyles({
  padding: '1.25rem'
});

const emptyWinListContainer = mergeStyles({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '3.8vw'
});
