import { DefaultButton, getTheme, MessageBarType, Stack, TextField } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { Bidder } from '../../types/bidder';
import { authContainer, authFormStackStyles, logoImageInFooter, submitButtonStyles } from '../styles/Auth.styles';
import { appSettings } from '../../appSettings';
import { useNavigate } from 'react-router-dom';
import { IsLoadingHOC } from '../common/IsLoadingHOC';
import pcaLandscapeLogoSVG from '../../assets/PCA-Landscape-Logo.svg';
import { wholeWindow } from '../styles/Common.styles';
import CreditScreen from './CreditScreen';
import { getASettings } from '../../services/settings.service';
import { useCookies } from 'react-cookie';
import { BidderCookie } from '../../types/bidderCookie';
import { signupBidder } from '../../services/bidder.service';
import { MessageBannerProps } from '../../types/messageBanner';
import { createMessageBanner, MessageBanner } from '../common/MessageBanner';

const palette = getTheme().palette;

type BidderSignUpScreenProps = {
  setLoading: (isLoading: boolean) => void;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const BidderSignUpScreen = (props: BidderSignUpScreenProps): JSX.Element => {
  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [accessCode, setAccessCode] = useState<string | undefined>();
  const [messageBanner, setMessageBanner] = useState<MessageBannerProps | undefined>(undefined);
  const [_, setCookie, removeCookie] = useCookies([appSettings.bidder.userInfoCookieName]);

  const handleQuit = () => {
    setMessageBanner(undefined);
  };

  useEffect(() => {
    // check if URL contains www, if no redirect to www
    const pathname = window.location.pathname;
    setTimeout(() => {
      props.setLoading(false);
    }, appSettings.bidder.splashScreenTTD + appSettings.bidder.creditScreenTTD);
  }, []);

  const navigate = useNavigate();

  const isValidSubmission = (): boolean => {
    return !firstName || !lastName || !accessCode;
  };

  const signupWithAccess = async () => {
    const bidderToSignup: Bidder = {
      firstName: firstName,
      lastName: lastName
    };

    const response = await signupBidder(bidderToSignup);

    if (!response.code) {
      const bidderCookie: BidderCookie = {
        id: response['_id'],
        name: `${firstName} ${lastName}`,
        token: response?.token
      };

      setCookie(appSettings.bidder.userInfoCookieName, bidderCookie, {
        path: '/',
        maxAge: appSettings.bidder.cookieMaxAge
      });

      navigate('/bidder/lots');
    } else {
      setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
    }
  };

  const signup = async () => {
    const response = await getASettings();

    !response.code
      ? accessCode == response.accessCode
        ? await signupWithAccess()
        : setMessageBanner(
            createMessageBanner(
              MessageBarType.error,
              'Fail to sign up because of incorrect access code. Please enter the correct one!'
            )
          )
      : setMessageBanner(
          createMessageBanner(MessageBarType.error, 'Fail to validate the access code. Please contact the registrar!')
        );
  };

  return (
    <>
      <div className={wholeWindow(palette.white)}>
        {messageBanner && (
          <MessageBanner
            messageBarType={messageBanner.messageBarType}
            messageContent={messageBanner.messageContent}
            handleQuit={handleQuit}
          />
        )}
        <Stack horizontalAlign="center" verticalAlign="center" className={authContainer}>
          <Stack tokens={{ childrenGap: 10, padding: 10 }} styles={authFormStackStyles}>
            <TextField
              label="名字 First Name"
              onChange={(_, value): void => {
                setFirstName(value);
              }}
              value={firstName}
              maxLength={20}
              required
            />
            <TextField
              label="姓氏 Last Name"
              onChange={(_, value): void => {
                setLastName(value);
              }}
              value={lastName}
              maxLength={20}
              required
            />
            <TextField
              label="通行密碼 Access Code"
              onChange={(_, value): void => {
                setAccessCode(value);
              }}
              value={accessCode}
              maxLength={20}
              required
            />
            <DefaultButton disabled={isValidSubmission()} styles={submitButtonStyles} text="Sign Up" onClick={signup} />
          </Stack>
          <img src={pcaLandscapeLogoSVG} alt="PCA Logo" className={logoImageInFooter} />
        </Stack>
      </div>
    </>
  );
};

export default IsLoadingHOC(BidderSignUpScreen, CreditScreen);
