import { Stack } from '@fluentui/react';

export default (): JSX.Element => {
  return (
    <>
      <Stack
        verticalAlign="space-evenly"
        tokens={{
          childrenGap: 5,
          padding: 10
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              background: 'rgb(158, 162, 255)',
              borderRadius: '26px',
              width: '64px',
              height: '3px',
              marginLeft: '2rem',
              marginTop: '1.3rem'
            }}
          ></div>
          <div
            style={{
              background: 'rgb(160, 160, 160)',
              borderRadius: '26px',
              width: '54px',
              height: '3px',
              marginRight: '14rem',
              marginTop: '1.5rem'
            }}
          ></div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              background: 'rgb(241, 241, 241)',
              borderRadius: '26px',
              width: '44px',
              height: '10px',
              marginTop: '1.3rem'
            }}
          ></div>
          <div
            style={{
              background: 'rgb(255, 102, 102)',
              borderRadius: '26px',
              width: '39px',
              height: '10px',
              marginLeft: '-4rem',
              marginTop: '1.2rem'
            }}
          ></div>
          <div
            style={{
              background: 'rgb(230, 240, 255)',
              borderRadius: '26px',
              width: '110px',
              height: '10px',
              marginTop: '1.6rem'
            }}
          ></div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <div
              style={{
                background: 'rgb(115, 227, 223)',
                borderRadius: '26px',
                width: '36px',
                height: '3px',
                marginLeft: '25rem'
              }}
            ></div>
          </div>
          <div
            style={{
              background: 'rgb(148, 117, 189)',
              borderRadius: '26px',
              width: '70px',
              height: '5px',
              marginLeft: '35rem'
            }}
          ></div>
          <div
            style={{
              background: 'rgb(241, 241, 241)',
              borderRadius: '26px',
              width: '63px',
              height: '10px',
              marginRight: '10rem'
            }}
          ></div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                background: 'rgb(252, 225, 0)',
                borderRadius: '26px',
                width: '140px',
                height: '9px',
                marginLeft: '7.5rem',
                marginTop: '0.7rem'
              }}
            ></div>
          </div>
          <div
            style={{
              background: 'rgb(227, 0, 140)',
              borderRadius: '26px',
              width: '36px',
              height: '3px',
              marginTop: '1.3rem',
              marginRight: '1.5rem'
            }}
          ></div>
        </div>
      </Stack>
    </>
  );
};
