import { getTheme, mergeStyles } from '@fluentui/react';
import PCALogoGIF from '../../assets/PCA-Logo-GIF.gif';
import { wholeWindow } from '../styles/Common.styles';

const palette = getTheme().palette;

export default (): JSX.Element => {
  return (
    <>
      <div className={wholeWindow(palette.white)}>
        <div className={footerContainer}>
          <img className={logoInFooter} src={PCALogoGIF} alt="PCA Logo" />
        </div>
      </div>
    </>
  );
};

const logoInFooter = mergeStyles({
  width: '15.625rem',
  marginBottom: '1rem'
});

const footerContainer = mergeStyles({
  width: '100%',
  position: 'fixed',
  bottom: '1rem',
  display: 'flex',
  justifyContent: 'center',
  color: palette.white
});
