import { getTheme, mergeStyles } from '@fluentui/react';
import { ReactComponent as ExchangeIconSVG } from '../../assets/icons/exchange-icon.svg';
import { ReactComponent as MoneyIconSVG } from '../../assets/icons/money-icon.svg';
import { ReactComponent as OpportunityIconSVG } from '../../assets/icons/opportunity-icon.svg';
import { ReactComponent as UnderstandIconSVG } from '../../assets/icons/understanding-icon.svg';
import { registrarSocket } from '../../utils/clientSockets';
import Events from '../../Events';
import { Offer } from '../../types/offer';
import { useState, useEffect } from 'react';

const palette = getTheme().palette;

type WinnerScreenProps = {
  offer?: Offer;
};

const iconsContainer = mergeStyles({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
});

const hasExchange = () => {
  return (
    <div className={iconsContainer}>
      <ExchangeIconSVG
        style={{
          position: 'fixed',
          top: '-1.1rem',
          right: '2.4rem',
          width: '12rem',
          zIndex: -1,
          rotate: '24deg',
          filter: 'invert(32%) sepia(32%) saturate(6591%) hue-rotate(77deg) brightness(93%) contrast(94%)'
        }}
      />
      <ExchangeIconSVG
        style={{
          position: 'fixed',
          top: '8.9rem',
          right: '32.4rem',
          width: '21.4rem',
          zIndex: -4,
          rotate: '154deg',
          filter: 'invert(32%) sepia(32%) saturate(6591%) hue-rotate(77deg) brightness(93%) contrast(94%)'
        }}
      />
      <ExchangeIconSVG
        style={{
          position: 'fixed',
          top: '-11.1rem',
          left: '2.4rem',
          width: '44rem',
          zIndex: -2,
          rotate: '124.1deg',
          filter: 'invert(32%) sepia(32%) saturate(6591%) hue-rotate(77deg) brightness(93%) contrast(94%)'
        }}
      />
      <ExchangeIconSVG
        style={{
          position: 'fixed',
          bottom: '14.8rem',
          left: '49.5rem',
          width: '4.4rem',
          zIndex: -1,
          rotate: '20.6deg',
          filter: 'invert(32%) sepia(32%) saturate(6591%) hue-rotate(77deg) brightness(93%) contrast(94%)'
        }}
      />
      <ExchangeIconSVG
        style={{
          position: 'fixed',
          bottom: '33.5rem',
          right: '76.3rem',
          width: '5rem',
          zIndex: -2,
          rotate: '56.2deg',
          filter: 'invert(32%) sepia(32%) saturate(6591%) hue-rotate(77deg) brightness(93%) contrast(94%)'
        }}
      />
      <ExchangeIconSVG
        style={{
          position: 'fixed',
          bottom: '-25.7rem',
          right: '26.4rem',
          width: '40.3rem',
          zIndex: -1,
          rotate: '56.9deg',
          filter: 'invert(32%) sepia(32%) saturate(6591%) hue-rotate(77deg) brightness(93%) contrast(94%)'
        }}
      />
      <ExchangeIconSVG
        style={{
          position: 'fixed',
          bottom: '24rem',
          right: '86rem',
          width: '6.3rem',
          zIndex: -1,
          rotate: '16.2deg',
          filter: 'invert(32%) sepia(32%) saturate(6591%) hue-rotate(77deg) brightness(93%) contrast(94%)'
        }}
      />
    </div>
  );
};

const hasMoney = () => {
  return (
    <div className={iconsContainer}>
      <MoneyIconSVG
        style={{
          position: 'fixed',
          top: '-2.4rem',
          left: '-2.9rem',
          width: '15.4rem',
          zIndex: -1,
          filter: 'invert(84%) sepia(16%) saturate(1686%) hue-rotate(351deg) brightness(94%) contrast(95%)'
        }}
      />
      <MoneyIconSVG
        style={{
          position: 'fixed',
          top: '8.1rem',
          left: '32.6rem',
          width: '35.3rem',
          zIndex: -4,
          rotate: '-23.2deg',
          filter: 'invert(84%) sepia(16%) saturate(1686%) hue-rotate(351deg) brightness(94%) contrast(95%)'
        }}
      />
      <MoneyIconSVG
        style={{
          position: 'fixed',
          top: '-31.9rem',
          right: '-14.9rem',
          width: '55.4rem',
          zIndex: -1,
          filter: 'invert(84%) sepia(16%) saturate(1686%) hue-rotate(351deg) brightness(94%) contrast(95%)'
        }}
      />
      <MoneyIconSVG
        style={{
          position: 'fixed',
          bottom: '-2.7rem',
          right: '40.1rem',
          width: '10.4rem',
          zIndex: -1,
          filter: 'invert(84%) sepia(16%) saturate(1686%) hue-rotate(351deg) brightness(94%) contrast(95%)'
        }}
      />
    </div>
  );
};

const hasOpportunity = () => {
  return (
    <div className={iconsContainer}>
      <OpportunityIconSVG
        style={{
          position: 'fixed',
          bottom: '-6.7rem',
          right: '-6.5rem',
          width: '50rem',
          zIndex: -5,
          filter: 'invert(16%) sepia(86%) saturate(7458%) hue-rotate(334deg) brightness(101%) contrast(104%)'
        }}
      />
      <OpportunityIconSVG
        style={{
          position: 'fixed',
          top: '-6.8rem',
          left: '63.2rem',
          width: '30.1rem',
          zIndex: -2,
          filter: 'invert(16%) sepia(86%) saturate(7458%) hue-rotate(334deg) brightness(101%) contrast(104%)'
        }}
      />
      <OpportunityIconSVG
        style={{
          position: 'fixed',
          bottom: '-9.5rem',
          right: '75.2rem',
          width: '30rem',
          zIndex: -1,
          filter: 'invert(16%) sepia(86%) saturate(7458%) hue-rotate(334deg) brightness(101%) contrast(104%)'
        }}
      />
      <OpportunityIconSVG
        style={{
          position: 'fixed',
          bottom: '-12rem',
          left: '-1.2rem',
          width: '20rem',
          zIndex: -1,
          filter: 'invert(16%) sepia(86%) saturate(7458%) hue-rotate(334deg) brightness(101%) contrast(104%)'
        }}
      />
      <OpportunityIconSVG
        style={{
          position: 'fixed',
          bottom: '24rem',
          left: '5.8rem',
          width: '10rem',
          zIndex: -1,
          filter: 'invert(16%) sepia(86%) saturate(7458%) hue-rotate(334deg) brightness(101%) contrast(104%)'
        }}
      />
    </div>
  );
};

const hasUnderstand = () => {
  return (
    <div className={iconsContainer}>
      <UnderstandIconSVG
        style={{
          position: 'fixed',
          bottom: '-2.8rem',
          left: '3.5rem',
          width: '37rem',
          zIndex: -3,
          rotate: '10.6deg',
          filter: 'invert(39%) sepia(38%) saturate(698%) hue-rotate(159deg) brightness(94%) contrast(96%)'
        }}
      />
      <UnderstandIconSVG
        style={{
          position: 'fixed',
          bottom: '26.8rem',
          right: '51.2rem',
          width: '77rem',
          zIndex: -3,
          rotate: '21.9deg',
          filter: 'invert(39%) sepia(38%) saturate(698%) hue-rotate(159deg) brightness(94%) contrast(96%)'
        }}
      />
      <UnderstandIconSVG
        style={{
          position: 'fixed',
          top: '24.2rem',
          right: '29.1rem',
          width: '7rem',
          zIndex: -3,
          rotate: '135.3deg',
          filter: 'invert(39%) sepia(38%) saturate(698%) hue-rotate(159deg) brightness(94%) contrast(96%)'
        }}
      />
      <UnderstandIconSVG
        style={{
          position: 'fixed',
          top: '3.2rem',
          right: '44.7rem',
          width: '5rem',
          zIndex: -3,
          rotate: '-28.1deg',
          filter: 'invert(39%) sepia(38%) saturate(698%) hue-rotate(159deg) brightness(94%) contrast(96%)'
        }}
      />
      <UnderstandIconSVG
        style={{
          position: 'fixed',
          bottom: '-1rem',
          right: '0.9rem',
          width: '5rem',
          zIndex: -3,
          rotate: '221.9deg',
          filter: 'invert(39%) sepia(38%) saturate(698%) hue-rotate(159deg) brightness(94%) contrast(96%)'
        }}
      />
    </div>
  );
};

export default (props: WinnerScreenProps): JSX.Element => {
  const [offer, setOffer] = useState<Offer>();
  const [bidderName, setBidderName] = useState<string[]>();
  const [exchange, setExchange] = useState<boolean>(false);
  const [money, setMoney] = useState<boolean>(false);
  const [opportunity, setOpportunity] = useState<boolean>(false);
  const [understand, setUnderstand] = useState<boolean>(false);

  useEffect(() => {
    setOffer(props.offer);
    registrarSocket.on(Events.CHOOSE_WINNER, (offer: Offer) => {
      setOffer(offer);
    });
  }, []);

  useEffect(() => {
    if (!exchange) setExchange(offer?.exchange ? true : false);
    if (!money) setMoney(offer?.money ? true : false);
    if (!opportunity) setOpportunity(offer?.opportunity ? true : false);
    if (!understand) setUnderstand(offer?.understanding ? true : false);
    // check if we already have a bidder name if yes add the current winner to the list
    if (offer && !bidderName) {
      setBidderName([offer.bidder?.bidderName ?? 'Anonymous']);
    } else if (offer && bidderName) {
      // check if name already exists in the list
      // for (let i = 0; i < bidderName.length; i += 2) {
      //   if (bidderName[i] === offer.bidder?.bidderName[0] && bidderName[i + 1] === offer.bidder?.bidderName[1]) {
      //     return;
      //   }
      //   }
      if (offer.bidder?.bidderName) setBidderName([...bidderName, offer.bidder?.bidderName]);
    }
  }, [offer]);

  return (
    <>
      <div className={winnerName}>
        <p>{bidderName ? bidderName.join(' & ') : ''}</p>
      </div>
      {exchange && hasExchange()}
      {money && hasMoney()}
      {opportunity && hasOpportunity()}
      {understand && hasUnderstand()}
    </>
  );
};

const winnerName = mergeStyles({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '4vw',
  fontWeight: '800',
  color: palette.black,
  textTransform: 'uppercase',
  zIndex: 1
});
