import { DefaultButton, IconButton, MessageBarType, Panel, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { appSettings } from '../../appSettings';
import Events from '../../Events';
import { createASettings, getASettings } from '../../services/settings.service';
import { DashboardScreen } from '../../types/DashboardScreen';
import { HttpStatusCode } from '../../types/HttpStatusCode';
import { MessageBannerProps } from '../../types/messageBanner';
import { RegistrarCookie } from '../../types/registrarCookie';
import { Settings } from '../../types/settings';
import { createMessageBanner, MessageBanner } from '../common/MessageBanner';
import {
  headerButton,
  headerContainer,
  headerIconButton,
  headerIconButtonContainer,
  headerTitleContainer,
  itemAlignmentsStackTokens,
  settingsStackTokens
} from '../styles/Header.styles';
import AccessCode from './Settings/AccessCode';
import WiFi from './Settings/WiFi';

/* eslint-disable @typescript-eslint/no-explicit-any */
type HeaderProps = {
  messageBanner?: MessageBannerProps;
  setMessageBanner: (messageBannerProps: MessageBannerProps) => void;
  setScreen: (screen: string) => void;
  registrarSocket: any;
  signout: () => void;
};

export default (props: HeaderProps): JSX.Element => {
  const [accessCode, setAccessCode] = useState<string | undefined>(appSettings.registrar.initialAccessCode);
  const [wifiSSID, setWIFISSID] = useState<string | undefined>();
  const [wifiPassword, setWIFIPassword] = useState<string | undefined>();
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [cookies] = useCookies([appSettings.registrar.userInfoCookieName]);
  const registrarToken = (cookies[appSettings.registrar.userInfoCookieName] as RegistrarCookie)?.token;

  useEffect(() => {
    (async () => {
      const settingsResponse = await getASettings();
      settingsResponse.accessCode && setAccessCode(settingsResponse.accessCode);
      settingsResponse.wifiSSID && setWIFISSID(settingsResponse.wifiSSID);
      settingsResponse.wifiPassword && setWIFIPassword(settingsResponse.wifiPassword);
    })();
  }, []);

  const onDismiss = useCallback(() => {
    (async () => {
      const settingsToCreate: Settings = {
        accessCode: accessCode ?? '',
        wifiSSID: wifiSSID ?? '',
        wifiPassword: wifiPassword ?? ''
      };

      const response = await createASettings(registrarToken, settingsToCreate);

      dismissPanel();

      if (!response.code) {
        props.registrarSocket.emit(Events.CONFIGURE_SETTINGS, response);
      } else if (response.code === HttpStatusCode.UNAUTHORIZED) {
        props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
        setTimeout(props.signout, appSettings.defaultWaitingTimeToSignoutInMilliseconds);
      } else {
        props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
      }
    })();
  }, [accessCode, wifiSSID, wifiPassword, dismissPanel]);

  return (
    <>
      <Stack
        horizontal
        horizontalAlign="space-between"
        id="header"
        className={headerContainer}
        tokens={itemAlignmentsStackTokens}
      >
        <Stack verticalAlign="center" className={headerTitleContainer}>
          <span>Post Capitalistic Auction | Dashboard</span>
        </Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10 }}
          styles={{ root: { marginLeft: '-11rem !important' } }}
        >
          <DefaultButton
            text="Artworks"
            iconProps={{ iconName: 'Photo2' }}
            className={headerButton}
            onClick={() => props.setScreen(DashboardScreen.ARTWORKS)}
          />
          <DefaultButton
            text="Bidders"
            iconProps={{ iconName: 'IDBadge' }}
            className={headerButton}
            onClick={() => props.setScreen(DashboardScreen.BIDDERS)}
          />
        </Stack>
        <Stack horizontal horizontalAlign="space-between" className={headerIconButtonContainer}>
          <IconButton
            className={headerIconButton}
            iconProps={{ iconName: 'Settings' }}
            title="Settings"
            ariaLabel="Settings"
            onClick={openPanel}
          />
          <Panel
            headerText="Settings"
            isOpen={isOpen}
            onDismiss={onDismiss}
            // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
            closeButtonAriaLabel="Close"
            isLightDismiss={true}
          >
            <Stack verticalAlign="space-between" horizontalAlign="start" tokens={settingsStackTokens}>
              <AccessCode accessCode={accessCode} setAccessCode={setAccessCode} />
              <WiFi
                wifiSSID={wifiSSID}
                setWIFISSID={setWIFISSID}
                wifiPassword={wifiPassword}
                setWIFIPassword={setWIFIPassword}
              />
            </Stack>
          </Panel>
          <IconButton
            className={headerIconButton}
            iconProps={{ iconName: 'SignOut' }}
            title="Sign Out"
            ariaLabel="SignOut"
            onClick={props.signout}
          />
        </Stack>
      </Stack>
      {props.messageBanner && (
        <MessageBanner
          messageBarType={props.messageBanner.messageBarType}
          messageContent={props.messageBanner.messageContent}
        />
      )}
    </>
  );
};
