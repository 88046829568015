import { Stack } from '@fluentui/react';
import BidFooter from '../../components/bidder/BidFooter';
import LotInfoScreen from '../../components/bidder/LotInfoScreen';

export default (): JSX.Element => {
  return (
    <>
      <Stack>
        <LotInfoScreen />
        <BidFooter />
      </Stack>
    </>
  );
};
