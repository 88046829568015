import { appSettings } from '../appSettings';
import { Bidder } from '../types/bidder';
import { HttpStatusCode } from '../types/HttpStatusCode';

export const signupBidder = async (bidderToSignup: Bidder) => {
  const requestUrl = `${appSettings.host.artAuctionServerHost}/api/bidders/signup`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firstName: bidderToSignup.firstName,
      lastName: bidderToSignup.lastName
    })
  };

  const response = await fetch(requestUrl, requestOptions);

  return response.json();
};

export const getAllBidders = async (token: string) => {
  const requestUrl = `${appSettings.host.artAuctionServerHost}/api/bidders`;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  };

  const response = await fetch(requestUrl, requestOptions);

  return response.json();
};

export const getASelectedBidder = async (token: string, bidderId?: string) => {
  if (bidderId) {
    const requestUrl = `${appSettings.host.artAuctionServerHost}/api/bidders/${bidderId}`;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    };
    const response = await fetch(requestUrl, requestOptions);

    return response.json();
  } else {
    console.log('The selected bidder ID is null.');
  }
};

export const updateABidderById = async (token: string, bidderId: string, bidderUpdateInfo: Bidder) => {
  if (bidderId) {
    const requestUrl = `${appSettings.host.artAuctionServerHost}/api/bidders/${bidderId}`;
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(bidderUpdateInfo)
    };

    const response = await fetch(requestUrl, requestOptions);

    return response.json();
  } else {
    console.log('The selected bidder ID is null.');
  }
};

export const deleteASelectedBidder = async (token: string, bidderId: string) => {
  const requestUrl = `${appSettings.host.artAuctionServerHost}/api/bidders/${bidderId}`;
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  };

  const response = await fetch(requestUrl, requestOptions);

  if (response.status !== HttpStatusCode.NO_CONTENT) {
    return await response.json();
  }

  return { code: HttpStatusCode.NO_CONTENT };
};

export const deleteAllBidders = async (token: string) => {
  const requestUrl = `${appSettings.host.artAuctionServerHost}/api/bidders`;
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  };

  const response = await fetch(requestUrl, requestOptions);

  if (response.status !== HttpStatusCode.NO_CONTENT) {
    return await response.json();
  }

  return { code: HttpStatusCode.NO_CONTENT };
};

export const getAWinList = async (token: string, bidderId: string) => {
  if (bidderId) {
    const requestUrl = `${appSettings.host.artAuctionServerHost}/api/bidders/${bidderId}/winlist`;

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    };
    const response = await fetch(requestUrl, requestOptions);

    return response.json();
  } else {
    console.log('The selected bidder ID is null.');
  }
};
