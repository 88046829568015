import { DefaultButton, MessageBarType, Stack, TextField } from '@fluentui/react';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { appSettings } from '../../appSettings';
import { signinRegistrar } from '../../services/registrar.service';
import { HttpStatusCode } from '../../types/HttpStatusCode';
import { MessageBannerProps } from '../../types/messageBanner';
import { Registrar } from '../../types/registrar';
import { RegistrarCookie } from '../../types/registrarCookie';
import { createMessageBanner } from '../common/MessageBanner';
import { authFormStackStyles, submitButtonStyles } from '../styles/Auth.styles';

type SignInScreenProps = {
  changeAuthMode: () => void;
  setMessageBanner: (messageBannerProps: MessageBannerProps) => void;
};

export default (props: SignInScreenProps): JSX.Element => {
  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [_, setCookie] = useCookies([appSettings.registrar.userInfoCookieName]);

  const navigate = useNavigate();

  const isValidSubmission = (): boolean => {
    return !email || !password;
  };

  const signin = async () => {
    const registrarInfoToSignin: Registrar = {
      email: email,
      password: password
    };

    const response = await signinRegistrar(registrarInfoToSignin);

    if (!response.code) {
      const registrarCookie: RegistrarCookie = { id: response['_id'], token: response?.token };
      setCookie(appSettings.registrar.userInfoCookieName, registrarCookie, {
        path: '/registrar'
      });

      navigate('/registrar');
    } else {
      props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
    }
  };

  return (
    <Stack tokens={{ childrenGap: 10, padding: 10 }} styles={authFormStackStyles}>
      <TextField
        label="Email"
        onChange={(_, value): void => {
          setEmail(value);
        }}
        value={email}
        maxLength={20}
        required
      />
      <TextField
        label="Password"
        type="password"
        onChange={(_, value): void => {
          setPassword(value);
        }}
        value={password}
        maxLength={20}
        required
      />
      <DefaultButton disabled={isValidSubmission()} styles={submitButtonStyles} text="Sign In" onClick={signin} />
      <div style={{ textAlign: 'center' }}>
        Not registered yet? <span onClick={props.changeAuthMode}>Sign Up</span>
      </div>
    </Stack>
  );
};
