import { useEffect, useState } from 'react';
import LandingScreen from '../../components/projector/LandingScreen';
import LotScreen from '../../components/projector/LotScreen';
import WinnerScreen from '../../components/projector/WinnerScreen';
import { Lot } from '../../types/lot';
import { registrarSocket } from '../../utils/clientSockets';
import Events from '../../Events';
import OfferBoardScreen from '../../components/projector/OfferBoardScreen';
import { Offer } from '../../types/offer';
import { Settings } from '../../types/settings';
import { getASettings } from '../../services/settings.service';

export default (): JSX.Element => {
  const [settings, setSettings] = useState<Settings>();
  const [activeLot, setActiveLot] = useState<Lot>();
  const [winnerOffer, setWinnerOffer] = useState<Offer>();
  const [projectorStatus, setProjectorStatus] = useState<string | undefined>();
  const [screenToproject, setScreenToproject] = useState<JSX.Element>();

  useEffect(() => {
    (async () => {
      const settingsResponse = await getASettings();
      setSettings(settingsResponse);
      setScreenToproject(<LandingScreen settings={settingsResponse} />);
    })();

    registrarSocket.on(Events.PROJECT_LOT, (lot: Lot) => {
      setActiveLot(lot);
      setProjectorStatus('lotToProject');
    });

    registrarSocket.on(Events.PROJECT_HOME, () => {
      setProjectorStatus('homeScreen');
    });

    registrarSocket.on(Events.BID_LOT, (lot: Lot) => {
      setActiveLot(lot);
      setProjectorStatus('lotToBid');
    });

    registrarSocket.on(Events.CHOOSE_WINNER, (offer: Offer) => {
      setWinnerOffer(offer);
      setProjectorStatus('lotBidResult');
    });

    registrarSocket.on(Events.CONFIGURE_SETTINGS, (settings: Settings) => {
      setSettings(settings);
    });

    return () => {
      registrarSocket.off(Events.PROJECT_LOT);
      registrarSocket.off(Events.BID_LOT);
      registrarSocket.off(Events.CHOOSE_WINNER);
      registrarSocket.off(Events.CONFIGURE_SETTINGS);
    };
  }, []);

  useEffect(() => {
    if (projectorStatus === 'lotToProject') {
      const lotScreenProps = {
        lotIndex: activeLot?.lotIndex,
        title: activeLot?.title,
        artists: activeLot?.artists,
        countries: activeLot?.countries,
        imgUrl: activeLot?.imgUrl
      };
      setScreenToproject(<LotScreen {...lotScreenProps} />);
    } else if (projectorStatus === 'lotToBid') {
      setScreenToproject(<OfferBoardScreen lot={activeLot} />);
    } else if (projectorStatus == 'lotBidResult') {
      setScreenToproject(<WinnerScreen offer={winnerOffer} />);
    } else {
      setScreenToproject(<LandingScreen settings={settings} />);
    }
  }, [projectorStatus, activeLot, settings]);

  return <>{screenToproject}</>;
};
