import { mergeStyles, Stack } from '@fluentui/react';

type BidHeaderProps = {
  lotIndex?: string;
  lotTitle?: string;
};

export default (props: BidHeaderProps): JSX.Element => {
  const lotIndexString = `拍品 Lot ${props.lotIndex}`;
  return (
    <Stack verticalAlign="space-around" horizontalAlign="start" className={lotInfoContainer}>
      <h2 style={{ fontFamily: 'Harbourfront Header', marginBottom: 0 }}>{lotIndexString}</h2>
      <p style={{ marginTop: '0.6rem' }}>{props.lotTitle}</p>
    </Stack>
  );
};

const lotInfoContainer = mergeStyles({
  padding: '1.25rem'
});
