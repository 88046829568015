export const formatMoneyString = (amountString: string): string => {
  // reduce it to maximum 1000000
  if (amountString.length >= 9) {
    amountString = amountString.slice(0, 8);
  }

  const amountNumber = isNaN(Number(amountString)) ? 0 : Number(amountString);

  return amountNumber.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};

export const convertCurrencyToNumberString = (currencyString: string): string => {
  return Number(currencyString.replace(/[^0-9.-]+/g, '')).toString();
};
