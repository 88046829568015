import { appSettings } from '../appSettings';
import { Registrar } from '../types/registrar';

export const signupRegistrar = async (registrarToSignup: Registrar) => {
  const requestUrl = `${appSettings.host.artAuctionServerHost}/api/registrars/signup`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firstName: registrarToSignup.firstName,
      lastName: registrarToSignup.lastName,
      email: registrarToSignup.email,
      password: registrarToSignup.password
    })
  };

  const response = await fetch(requestUrl, requestOptions);

  return response.json();
};

export const signinRegistrar = async (registrarInfoToSignin: Registrar) => {
  const requestUrl = `${appSettings.host.artAuctionServerHost}/api/registrars/signin`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: registrarInfoToSignin.email,
      password: registrarInfoToSignin.password
    })
  };

  const response = await fetch(requestUrl, requestOptions);

  return response.json();
};
