import { getTheme, ITextFieldStyles, Stack, TextField } from '@fluentui/react';

const palette = getTheme().palette;

type WIFIProps = {
  wifiSSID?: string;
  setWIFISSID: (wifiSSID?: string) => void;
  wifiPassword?: string;
  setWIFIPassword: (wifiPassword?: string) => void;
};

export default (props: WIFIProps): JSX.Element => {
  return (
    <Stack styles={{ root: { width: '100%' } }}>
      <p style={{ color: palette.black, fontSize: '1rem', fontWeight: 600, marginBottom: '0.2rem' }}>Wi-Fi</p>
      <TextField
        label="SSID:"
        underlined
        placeholder="Enter Wi-Fi SSID"
        styles={wifiTextFieldStyles}
        onChange={(_, value): void => {
          props.setWIFISSID(value);
        }}
        value={props.wifiSSID ?? ''}
        maxLength={50}
      />
      <TextField
        label="Password:"
        underlined
        placeholder="Enter Wi-Fi Password"
        styles={wifiTextFieldStyles}
        onChange={(_, value): void => {
          props.setWIFIPassword(value);
        }}
        value={props.wifiPassword ?? ''}
        maxLength={50}
      />
    </Stack>
  );
};

const wifiTextFieldStyles: Partial<ITextFieldStyles> = {
  subComponentStyles: {
    label: {
      root: {
        padding: '5px 0'
      }
    }
  }
};
