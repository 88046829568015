import { DefaultButton, getTheme, IconButton, mergeStyles, Stack } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { appSettings } from '../../appSettings';
import Events from '../../Events';
import { Offer } from '../../types/offer';
import { registrarSocket } from '../../utils/clientSockets';

const palette = getTheme().palette;

type BidFooterProps = {
  isMoneySelected?: boolean;
  isOpportunitySelected?: boolean;
  isUnderstandingSelected?: boolean;
  isExchangeSelected?: boolean;
  isValidBid?: boolean;
  isBidded?: boolean;
  onSubmit?: () => void;
  isBidding?: boolean;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export default (props: BidFooterProps): JSX.Element => {
  const [bidButtonBackgroundColor, setBidButtonBackgroundColor] = useState<string>(palette.white);
  const navigate = useNavigate();

  useEffect((): any => {
    registrarSocket.on(Events.BID_LOT, (lot: any) => {
      const lotId = lot['_id'];
      navigate(`/bidder/lots/${lotId}/bid`);
    });

    registrarSocket.on(Events.CHOOSE_WINNER, (offer: Offer) => {
      navigate({
        pathname: '/bidder/bidsuccess',
        search: `?winner=${offer.bidder?.bidderName}&money=${Boolean(offer.money)}&opportunity=${Boolean(
          offer.opportunity
        )}&understanding=${Boolean(offer.understanding)}&exchange=${Boolean(offer.exchange)}`
      });
    });

    return () => {
      registrarSocket.off(Events.BID_LOT);
      registrarSocket.off(Events.CHOOSE_WINNER);
    };
  }, []);

  useEffect(() => {
    setBidButtonBackgroundColor(() => {
      const selectedChoicesNumber = [
        props.isMoneySelected,
        props.isOpportunitySelected,
        props.isUnderstandingSelected,
        props.isExchangeSelected
      ].filter((isSelected: boolean | undefined) => isSelected).length;

      if (!selectedChoicesNumber) {
        return 'white';
      }

      const colorGradientStr = [];

      if (props.isMoneySelected) {
        colorGradientStr.push(`rgb(${appSettings.yellowRGBColorForMoneyChoice})`);
      }
      if (props.isOpportunitySelected) {
        colorGradientStr.push(`rgb(${appSettings.magentaRGBColorForOpportunityChoice})`);
      }
      if (props.isUnderstandingSelected) {
        colorGradientStr.push(`rgb(${appSettings.blueRGBColorForUnderstandingChoice})`);
      }
      if (props.isExchangeSelected) {
        colorGradientStr.push(`rgb(${appSettings.greenRGBColorForExchangeChoice})`);
      }

      return selectedChoicesNumber == 1 ? colorGradientStr[0] : `linear-gradient(${colorGradientStr.join(', ')})`;
    });
  }, [props.isMoneySelected, props.isOpportunitySelected, props.isUnderstandingSelected, props.isExchangeSelected]);

  return (
    <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className={bidFooterContainer}>
      <IconButton
        styles={iconButtonStyles}
        iconProps={{ iconName: 'Home' }}
        title="Home"
        ariaLabel="Home"
        onClick={() => {
          // check if curent page is /bidder/lots/ and refresh the page
          if (window.location.pathname === '/bidder/lots') {
            window.location.reload();
          } else {
            navigate('/bidder/lots');
          }
        }}
      />
      {props.isBidding && (
        <DefaultButton
          disabled={props.isBidded || !props.isValidBid}
          text={!props.isBidded && props.isValidBid ? 'Bid' : ''}
          className={
            !props.isBidded && props.isValidBid ? bidCircleButton(bidButtonBackgroundColor) : bidCircleButtonBaseStyle
          }
          onClick={props.onSubmit}
        />
      )}
      <IconButton
        styles={iconButtonStyles}
        iconProps={{ iconName: 'VerifiedBrand' }}
        title="Lots Won"
        ariaLabel="LotsWon"
        onClick={() => navigate('/bidder/winlist')}
      />
    </Stack>
  );
};

const bidFooterContainer = mergeStyles({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  height: '7vh',
  padding: '0 1rem',
  backgroundColor: 'black'
});

const bidCircleButtonBaseStyle = mergeStyles({
  position: 'fixed',
  width: '4.375rem',
  height: '4.375rem',
  minWidth: 0,
  bottom: '3.8%',
  left: 0,
  right: 0,
  margin: '0 auto',
  borderWidth: '0.25rem',
  borderStyle: 'solid',
  borderColor: palette.white,
  borderRadius: '50%',
  textTransform: 'uppercase',
  background: palette.white,
  selectors: {
    '& .ms-Button-label': {
      fontSize: '1.3rem'
    }
  }
});

const bidCircleButton = (backgroundColor: string) =>
  mergeStyles(bidCircleButtonBaseStyle, {
    background: backgroundColor
  });

const iconButtonStyles = {
  icon: { color: 'white', fontSize: 30 },
  root: {
    backgroundColor: 'black'
  }
};
