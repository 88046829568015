import { getTheme, IStackItemStyles, IStackTokens, mergeStyles, Stack } from '@fluentui/react';

const palette = getTheme().palette;

type LotScreenProps = {
  lotIndex?: string;
  title?: string;
  artists?: string;
  countries?: string;
  imgUrl?: string;
};

export default (props: LotScreenProps): JSX.Element => {
  const lotIndexString = `Lot ${props.lotIndex ?? ''}`;
  return (
    <Stack enableScopedSelectors horizontal tokens={stackTokens} className={lotScreenContainer}>
      <Stack.Item styles={stackItemStyles} className={lotImageContainer}>
        <img src={props.imgUrl} alt="Lot Image" className={lotImage} />
      </Stack.Item>
      <Stack.Item styles={stackItemStyles} className={lotContentContainer}>
        <Stack styles={lotContentStackItemStyles}>
          <p style={{ fontFamily: 'Harbourfront Header', fontSize: '2.375rem' }}>{lotIndexString}</p>
          <h3 style={{ fontFamily: 'Harbourfront Body Bold', margin: '0.2rem 0' }}>{props.title ?? 'Undefined'}</h3>
          <p style={{ margin: 0, textAlign: 'left' }}>{props.artists ?? 'Undefined'}</p>
          <p style={{ margin: 0 }}>{props.countries ?? 'Undefined'}</p>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

const lotContentContainer = mergeStyles({
  width: '20vw',
  paddingLeft: '1.6125rem'
});

const lotContentStackItemStyles: IStackItemStyles = {
  root: {
    fontSize: '1.75rem',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    wordBreak: 'break-word'
  }
};

const lotImage = mergeStyles({
  height: '100%'
});

const lotImageContainer = mergeStyles({
  width: '80vw'
});

const lotScreenContainer = mergeStyles({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: '0.625rem'
});

const stackItemStyles: IStackItemStyles = {
  root: {
    alignItems: 'center',
    color: palette.black,
    display: 'flex',
    justifyContent: 'center'
  }
};

const stackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10
};
