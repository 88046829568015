import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import { initializeIcons, PartialTheme, registerIcons, ThemeProvider } from '@fluentui/react';
import { ReactComponent as ExchangeIconSVG } from './assets/icons/exchange-icon.svg';
import { ReactComponent as MoneyIconSVG } from './assets/icons/money-icon.svg';
import { ReactComponent as OpportunityIconSVG } from './assets/icons/opportunity-icon.svg';
import { ReactComponent as UnderstandIconSVG } from './assets/icons/understanding-icon.svg';

initializeIcons();

registerIcons({
  icons: {
    HfCExchange: <ExchangeIconSVG />,
    HfCMoney: <MoneyIconSVG />,
    HfCOpportunity: <OpportunityIconSVG />,
    HfCUnderstand: <UnderstandIconSVG />
  }
});

const appTheme: PartialTheme = {
  palette: {
    themeDarker: '#000000',
    themeDark: '#201f1e',
    themeDarkAlt: '#323130',
    themePrimary: '#000000',
    themeSecondary: '#323130',
    themeTertiary: '#605e5c',
    themeLight: '#a19f9d',
    themeLighter: '#d2d0ce',
    themeLighterAlt: '#edebe9'
  },
  defaultFontStyle: {
    fontFamily:
      'Harbourfront Body Regular, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
  }
};

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <ThemeProvider theme={appTheme}>
        <App />
      </ThemeProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
