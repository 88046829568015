import { DefaultButton, Spinner, Stack } from '@fluentui/react';
import { activeButtonStyles } from '../../styles/Common.styles';

type LotCreationDialogFooterProps = {
  guid?: string;
  createDisabled: boolean;
  creatingLot: boolean;
  onCreate: () => void;
  onClose: () => void;
};

export const LotCreationDialogFooter = (props: LotCreationDialogFooterProps): JSX.Element => (
  <Stack tokens={{ childrenGap: '0.5rem' }}>
    <Stack.Item>
      <Stack horizontal tokens={{ childrenGap: '1rem' }}>
        {!props.guid && (
          <Stack.Item>
            <DefaultButton
              styles={activeButtonStyles}
              disabled={props.createDisabled || props.creatingLot}
              onClick={props.onCreate}
              text={props.creatingLot ? undefined : 'Create'}
            >
              {props.creatingLot && <Spinner ariaLabel="Creating Artwork" />}
            </DefaultButton>
          </Stack.Item>
        )}
        <Stack.Item>
          <DefaultButton onClick={props.onClose} text="Close" />
        </Stack.Item>
      </Stack>
    </Stack.Item>
  </Stack>
);
