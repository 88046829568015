import { DefaultPalette, IStackStyles, mergeStyles, Stack } from '@fluentui/react';

export default (): JSX.Element => {
  return (
    <Stack horizontal horizontalAlign="center" verticalAlign="center" styles={notFoundErrorContainerStack}>
      <span className={contentContainer}>404</span>
    </Stack>
  );
};

const notFoundErrorContainerStack: IStackStyles = {
  root: {
    width: '100vw',
    height: '100vh',
    // background:'url(https://harbourfrontcentre.com/wp-content/uploads/2021/10/HC-MainBuilding.jpg) no-repeat center center',
    backgroundSize: 'cover'
  }
};

const contentContainer = mergeStyles({
  display: 'flex',
  width: 50,
  height: 50,
  fontSize: '21rem',
  fontWeight: '500',
  color: DefaultPalette.black,
  alignItems: 'center',
  justifyContent: 'center',
  '@media only screen and (max-width: 600px)': {
    fontSize: '9rem'
  }
});
