import { Modal, Stack } from '@fluentui/react';
import { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import { appSettings } from '../../../appSettings';
import { isBlobStorageConfigured, uploadFileToBlob } from '../../../services/azureBlob.service';
import { RegistrarCookie } from '../../../types/registrarCookie';
import { Lot } from '../../../types/lot';
import { dialogContainerStyles } from '../../styles/Common.styles';
import { LotCreationDialogBody } from './LotCreationDialog.body';
import { LotCreationDialogFooter } from './LotCreationDialog.footer';
import { LotCreationDialogHeader } from './LotCreationDialog.header';
import { createALot } from '../../../services/lot.service';

type LotCreationDialogProps = {
  isOpen: boolean;
  refreshKey: number;
  onDismiss: () => void;
  resetFields: () => void;
  setRefreshKey: (refreshKey: number) => void;
};

// const storageConfigured = isBlobStorageConfigured();

/* eslint-disable @typescript-eslint/no-explicit-any */
export default (props: LotCreationDialogProps): JSX.Element => {
  const [guid, setGuid] = useState<string | undefined>();
  const [lotIndex, setLotIndex] = useState<string | undefined>();
  const [title, setTitle] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();
  const [artists, setArtists] = useState<string | undefined>();
  const [countries, setCountries] = useState<string | undefined>();
  const [lotImage, setLotImage] = useState<any | undefined>();
  const [creatingLot, setCreatingLot] = useState<boolean>(false);
  const [cookies] = useCookies([appSettings.registrar.userInfoCookieName]);
  const adminToken = (cookies[appSettings.registrar.userInfoCookieName] as RegistrarCookie)?.token;

  const handleLotImage = (uploadedLotImage: any) => {
    setLotImage(uploadedLotImage);
  };

  const createLot = useCallback(async () => {
    setCreatingLot(true);

    try {
      const imgUrl = await uploadFileToBlob(lotImage);

      const lotToCreate: Lot = {
        lotIndex: lotIndex,
        title: title,
        description: description,
        artists: artists,
        countries: countries,
        imgUrl: imgUrl
      };

      const lot = await createALot(adminToken, lotToCreate);

      props.setRefreshKey(props.refreshKey + 1);
      setGuid(lot['_id']);
    } catch (e) {
      console.log('Fail to create a lot. Please try again later.');
    } finally {
      setCreatingLot(false);
    }
  }, [lotIndex, title, description, artists, countries, lotImage, setCreatingLot]);

  const isValidLot = (): boolean => {
    return !lotIndex || isNaN(Number(lotIndex)) || !title || !description || !artists || !countries || !lotImage;
  };

  const dismiss = (): void => {
    if (guid) {
      props.resetFields();
    }
    props.onDismiss();
  };

  return (
    <>
      <Modal isOpen={props.isOpen} onDismiss={dismiss} styles={dialogContainerStyles}>
        <Stack tokens={{ childrenGap: '1rem' }}>
          <Stack.Item>
            <LotCreationDialogHeader lotCreated={!!guid} />
          </Stack.Item>
          <Stack.Item>
            <LotCreationDialogBody
              guid={guid}
              lotIndex={lotIndex}
              title={title}
              description={description}
              artists={artists}
              countries={countries}
              setLotIndex={setLotIndex}
              setTitle={setTitle}
              setDescription={setDescription}
              setArtists={setArtists}
              setCountries={setCountries}
              handleLotImage={handleLotImage}
            />
          </Stack.Item>
          <Stack.Item>
            <LotCreationDialogFooter
              guid={guid}
              createDisabled={isValidLot()}
              creatingLot={creatingLot}
              onCreate={createLot}
              onClose={dismiss}
            />
          </Stack.Item>
        </Stack>
      </Modal>
    </>
  );
};
