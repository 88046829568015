export const appSettings = {
  rowNumberOnOfferBoard: 10,
  defaultWaitingTimeToSignoutInMilliseconds: 5000,
  yellowRGBColorForMoneyChoice: '233,190,81',
  magentaRGBColorForOpportunityChoice: '255,0,88',
  blueRGBColorForUnderstandingChoice: '49,113,152',
  greenRGBColorForExchangeChoice: '40,128,8',
  host: {
    // artAuctionClientHost: 'https://witty-cliff-0dbb9ad00.5.azurestaticapps.net',
    artAuctionClientHost: 'https://www.pca-hk.com',
    artAuctionServerHost: 'https://postcapitalistic-server-c4d8afc7huf2h2ef.eastasia-01.azurewebsites.net'
    //artAuctionClientHost: 'http://localhost:3000'
    //artAuctionServerHost: 'http://localhost:5000'
  },
  bidder: {
    splashScreenTTD: 2000, // milliseconds
    creditScreenTTD: 1500, // milliseconds
    userInfoCookieName: 'bidder-info',
    cookieMaxAge: 60 * 60 * 21 //
  },
  registrar: {
    rowNumberOnOfferBoard: 30,
    userInfoCookieName: 'registrar-info',
    lotCookieName: 'bidding-lot-info',
    initialAccessCode: 'HC2022PCA'
  },
  azureBlob: {
    containerName: 'public-container',
    sasToken:
      'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-03-04T18:19:13Z&st=2024-08-12T10:19:13Z&spr=https,http&sig=BZ7xkO0FSgrek5rnAkSVqf6S48VJJpKZ4gXMRxekVwI%3D',
    storageAccountName: 'pcablobstore'
  }
};
