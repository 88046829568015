import {
  getTheme,
  IButtonStyles,
  IIconStyleProps,
  IIconStyles,
  IModalStyles,
  IStyleFunctionOrObject,
  mergeStyles
} from '@fluentui/react';

const palette = getTheme().palette;

const activeButtonStyles: IButtonStyles = {
  root: {
    backgroundColor: palette.black,
    color: palette.white
  }
};

const dialogContainerStyles: Partial<IModalStyles> = {
  scrollableContent: {
    padding: '2rem',
    minWidth: '25vw',
    maxHeight: '80vh'
  }
};

const iconStyles: IStyleFunctionOrObject<IIconStyleProps, IIconStyles> = {
  root: {
    color: palette.black
  }
};

const wholeWindow = (backgroundColor: string) =>
  mergeStyles({
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: backgroundColor
  });

export { activeButtonStyles, dialogContainerStyles, iconStyles, wholeWindow };
