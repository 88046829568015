import React, { useCallback, useEffect, useState } from 'react';
import { IContextualMenuProps, MessageBarType, Separator, Stack } from '@fluentui/react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import {
  DetailsList,
  DetailsRow,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  Selection,
  SelectionMode
} from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection, TooltipHost } from '@fluentui/react';
import {
  fileIconCell,
  fileIconHeaderIcon,
  fileIconImg,
  itemAlignmentsStackTokens,
  dashboardListContainer,
  dashboardListTitle,
  dashboardListWrapStackTokens,
  separatorStyles
} from '../styles/LotsList.styles';
import { activeButtonStyles, iconStyles } from '../styles/Common.styles';
import DecorationBanner from '../common/DecorationBanner';
import LotCreationDialog from './LotCreationDialog/LotCreationDialog';
import { LotStatus } from '../../types/lotStatus';
import LotBulkImportDialog from './LotBulkImportDialog/LotBulkImportDialog';
import { appSettings } from '../../appSettings';
import { useCookies } from 'react-cookie';
import { RegistrarCookie } from '../../types/registrarCookie';
import Events from '../../Events';
import { deleteASelectedLot, getAllLots, getASelectedLot, updateALotById } from '../../services/lot.service';
import { MessageBannerProps } from '../../types/messageBanner';
import { HttpStatusCode } from '../../types/HttpStatusCode';
import { createMessageBanner } from '../common/MessageBanner';

/* eslint-disable @typescript-eslint/no-explicit-any */
type LotListProps = {
  setMessageBanner: (messageBannerProps: MessageBannerProps) => void;
  registrarSocket: any;
  signout: () => void;
};

export default (props: LotListProps): JSX.Element => {
  const [lots, setLots] = useState<any[]>([]);
  const [selectedLot, setSelectedLot] = useState<unknown | undefined>(undefined);
  const [lotCreationDialogOpen, setLotCreationDialogOpen] = useState(false);
  const [lotBulkImportDialogOpen, setLotBulkImportDialogOpen] = useState(false);
  const [dialogKey, setDialogKey] = useState<string>(`DialogKey: ${Math.random()}`);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [cookies, setCookie] = useCookies([
    appSettings.registrar.userInfoCookieName,
    appSettings.registrar.lotCookieName
  ]);
  const registrarToken = (cookies[appSettings.registrar.userInfoCookieName] as RegistrarCookie)?.token;

  const openLotCreationDialog = useCallback(() => {
    setLotCreationDialogOpen(true);
  }, []);
  const closeLotCreationDialog = useCallback(() => {
    setLotCreationDialogOpen(false);
  }, []);

  const openLotBulkImportDialog = useCallback(() => {
    setLotBulkImportDialogOpen(true);
  }, []);
  const closeLotBulkImportDialog = useCallback(() => {
    setLotBulkImportDialogOpen(false);
  }, []);

  const resetDialog = useCallback(() => {
    setDialogKey(`DialogKey: ${Math.random()}`);
  }, [setDialogKey]);

  useEffect(() => {
    (async () => {
      const response = await getAllLots(registrarToken);

      if (!response.code) {
        response.sort((lot1: any, lot2: any) => {
          const statusSortStatus = ['bidding', 'willBid', 'bidded'];
          if (statusSortStatus.indexOf(lot1.status) > statusSortStatus.indexOf(lot2.status)) return 1;
          if (statusSortStatus.indexOf(lot1.status) < statusSortStatus.indexOf(lot2.status)) return -1;
          if (Number(lot1.lotIndex) > Number(lot2.lotIndex)) return 1;
          if (Number(lot1.lotIndex) < Number(lot2.lotIndex)) return -1;
          return 0;
        });
        setLots(response);
      } else if (response.code === HttpStatusCode.UNAUTHORIZED) {
        props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
        setTimeout(props.signout, appSettings.defaultWaitingTimeToSignoutInMilliseconds);
      } else {
        props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
      }
    })();
  }, [refreshKey]);

  const _selection: Selection = new Selection({
    onSelectionChanged: () => {
      setSelectedLot(_selection.getSelection()[0]);
    }
  });

  const getSelectedLotId = (): string[] => {
    return (selectedLot &&
      Object.entries(selectedLot).find(([key, value]) => {
        return key === '_id';
      })) as string[];
  };

  const projectLot = async () => {
    const selectedLotId: string = getSelectedLotId()[1];
    const response = await getASelectedLot(registrarToken, selectedLotId);

    if (!response.code) {
      props.registrarSocket.emit(Events.PROJECT_LOT, response);
    } else if (response.code === HttpStatusCode.UNAUTHORIZED) {
      props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
      setTimeout(props.signout, appSettings.defaultWaitingTimeToSignoutInMilliseconds);
    } else {
      props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
    }
  };

  const bidLot = async () => {
    const selectedLotId: string = getSelectedLotId()[1];
    const response = await getASelectedLot(registrarToken, selectedLotId);

    if (!response.code) {
      if (response.status !== 'bidded') {
        props.registrarSocket.emit(Events.BID_LOT, response);
        setCookie(appSettings.registrar.lotCookieName, response, {
          path: `/registrar/${selectedLotId}/offerBoard`
        });
        window
          .open(`${appSettings.host.artAuctionClientHost}/registrar/${selectedLotId}/offerBoard`, '_blank')
          ?.focus();
        // Update Lot info -> lotStatus
        const lotUpdateInfo = { status: 'bidding' as LotStatus };
        await updateALotById(registrarToken, selectedLotId, lotUpdateInfo);
      }
    } else if (response.code === HttpStatusCode.UNAUTHORIZED) {
      props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
      setTimeout(props.signout, appSettings.defaultWaitingTimeToSignoutInMilliseconds);
    } else {
      props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
    }
  };

  const deleteLot = async () => {
    const lotId: string = getSelectedLotId()[1];

    const response = await deleteASelectedLot(registrarToken, lotId);

    if (response.code === HttpStatusCode.NO_CONTENT) {
      setRefreshKey(refreshKey - 1);
    } else if (response.code === HttpStatusCode.UNAUTHORIZED) {
      props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
      setTimeout(props.signout, appSettings.defaultWaitingTimeToSignoutInMilliseconds);
    } else {
      props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
    }
  };

  const projectMenuProps: IContextualMenuProps = {
    items: [
      {
        key: 'projectHomeScreen',
        text: 'Home Screen',
        iconProps: { iconName: 'Home', styles: iconStyles },
        onClick: () => {
          props.registrarSocket.emit(Events.PROJECT_HOME);
        }
      }
    ]
  };

  // const menuProps: IContextualMenuProps = {
  //   items: [
  //     {
  //       key: 'importFromCSV',
  //       text: 'Import from CSV',
  //       iconProps: { iconName: 'BulkUpload', styles: iconStyles },
  //       onClick: openLotBulkImportDialog
  //     }
  //   ]
  // };

  const _columns: IColumn[] = [
    {
      key: 'column1',
      name: 'File Type',
      className: fileIconCell,
      iconClassName: fileIconHeaderIcon,
      iconName: 'Page',
      isIconOnly: true,
      fieldName: 'fileType',
      minWidth: 32,
      maxWidth: 32,
      onRender: () => (
        <TooltipHost content="Lot Image File">
          <img
            src="https://static2.sharepointonline.com/files/fabric/assets/item-types/16/photo.svg"
            className={fileIconImg}
          />
        </TooltipHost>
      )
    },
    {
      key: 'column2',
      name: 'Order',
      fieldName: 'lotIndex',
      data: 'string',
      minWidth: 20,
      maxWidth: 40,
      isResizable: true,
      isPadded: true,
      onRender: (item) => {
        return <span>{item.lotIndex}</span>;
      }
    },
    {
      key: 'column3',
      name: 'Title',
      fieldName: 'title',
      data: 'string',
      minWidth: 200,
      maxWidth: 350,
      isResizable: true,
      isPadded: true,
      onRender: (item) => {
        return <span>{item.title}</span>;
      }
    },
    {
      key: 'column4',
      name: 'Artists',
      fieldName: 'artists',
      data: 'string',
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      isPadded: true,
      onRender: (item) => {
        return <span>{item.artists}</span>;
      }
    },
    {
      key: 'column5',
      name: 'Countries',
      fieldName: 'countries',
      data: 'string',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isPadded: true,
      onRender: (item) => {
        return <span>{item.countries}</span>;
      }
    },
    {
      key: 'column6',
      name: 'Winner',
      fieldName: 'winner',
      data: 'string',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isPadded: true,
      onRender: (item) => {
        return <span>{item.winner ?? ''}</span>;
      }
    }
  ];

  const renderRow: IDetailsListProps['onRenderRow'] = (props) => {
    let rowStyles;
    const itemStatus: LotStatus = props?.item.status;

    const biddedRowStyles: Partial<IDetailsRowStyles> = {
      root: {
        color: 'rgb(179, 179, 179)',
        textDecoration: 'line-through',
        selectors: {
          ':hover': {
            color: 'rgb(179, 179, 179)',
            textDecoration: 'line-through'
          }
        }
      }
    };

    const biddingRowStyles: Partial<IDetailsRowStyles> = {
      root: {
        fontWeight: '800',
        color: 'rgb(0, 0, 0)',
        selectors: {
          ':hover': {
            color: 'rgb(0, 0, 0)'
          }
        }
      }
    };

    const willBidRowStyles: Partial<IDetailsRowStyles> = {
      root: {
        fontWeight: '600',
        color: 'rgb(102, 102, 102)',
        selectors: {
          ':hover': {
            color: 'rgb(102, 102, 102)'
          }
        }
      }
    };

    if (itemStatus === 'bidded') {
      rowStyles = biddedRowStyles;
    } else if (itemStatus === 'bidding') {
      rowStyles = biddingRowStyles;
    } else {
      rowStyles = willBidRowStyles;
    }

    if (!props) return null;
    return <DetailsRow {...props} styles={rowStyles} />;
  };

  return (
    <>
      <Stack className={dashboardListContainer} tokens={dashboardListWrapStackTokens}>
        <DecorationBanner />
        <Stack horizontal horizontalAlign="space-between" tokens={itemAlignmentsStackTokens}>
          <Stack verticalAlign="center">
            <span className={dashboardListTitle}>Artworks</span>
          </Stack>
          <Stack horizontal horizontalAlign="end" tokens={itemAlignmentsStackTokens}>
            <DefaultButton
              text="Bid"
              styles={activeButtonStyles}
              iconProps={{ iconName: 'AllCurrency' }}
              onClick={bidLot}
            />
            <DefaultButton
              text="Project"
              split
              splitButtonAriaLabel="See 2 options"
              aria-roledescription="split button"
              iconProps={{ iconName: 'Upload' }}
              menuProps={projectMenuProps}
              onClick={projectLot}
            />
            <Separator styles={separatorStyles} vertical={true} />
            <DefaultButton
              text="New"
              split
              splitButtonAriaLabel="See 2 options"
              aria-roledescription="split button"
              iconProps={{ iconName: 'Add' }}
              // menuProps={menuProps}
              onClick={openLotCreationDialog}
            />
            {/* <DefaultButton text="Edit" iconProps={{ iconName: 'Edit' }} /> */}
            <DefaultButton text="Delete" iconProps={{ iconName: 'Delete' }} onClick={deleteLot} />
          </Stack>
        </Stack>
        <Stack>
          <MarqueeSelection selection={_selection}>
            <DetailsList
              items={lots}
              columns={_columns}
              onRenderRow={renderRow}
              setKey="set"
              checkButtonAriaLabel="select row"
              selection={_selection}
              selectionMode={SelectionMode.single}
            />
          </MarqueeSelection>
        </Stack>
      </Stack>
      {
        <LotCreationDialog
          key={dialogKey}
          isOpen={lotCreationDialogOpen}
          refreshKey={refreshKey}
          onDismiss={closeLotCreationDialog}
          resetFields={resetDialog}
          setRefreshKey={setRefreshKey}
        />
      }
      {
        <LotBulkImportDialog
          key={dialogKey + '1'}
          isOpen={lotBulkImportDialogOpen}
          refreshKey={refreshKey}
          onDismiss={closeLotBulkImportDialog}
          resetFields={resetDialog}
          setRefreshKey={setRefreshKey}
        />
      }
    </>
  );
};
