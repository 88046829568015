import { getTheme, mergeStyles, Stack } from '@fluentui/react';
import { useEffect } from 'react';
import { IsLoadingHOC } from '../common/IsLoadingHOC';
import SplashScreen from './SplashScreen';
import { wholeWindow } from '../styles/Common.styles';
import { appSettings } from '../../appSettings';

const palette = getTheme().palette;

type CreditScreenProps = {
  setLoading: (isLoading: boolean) => void;
};

const CreditScreen = (props: CreditScreenProps): JSX.Element => {
  useEffect(() => {
    setTimeout(() => {
      props.setLoading(false);
    }, appSettings.bidder.splashScreenTTD);
  }, []);

  return (
    <div className={wholeWindow(palette.black)}>
      <Stack verticalAlign="center" horizontalAlign="center" className={footerContainer}>
        <div>Loading ...</div>
      </Stack>
    </div>
  );
};

const logoInFooter = mergeStyles({
  width: '15.625rem',
  marginBottom: '1rem'
});

const footerContainer = mergeStyles({
  width: '100%',
  position: 'fixed',
  bottom: '1rem',
  display: 'flex',
  justifyContent: 'center',
  color: palette.white
});

export default IsLoadingHOC(CreditScreen, SplashScreen);
