import { MessageBar, MessageBarType } from '@fluentui/react';
import { useState } from 'react';
import { MessageBannerProps } from '../../types/messageBanner';

export const createMessageBanner = (messageBarType: MessageBarType, messageContent: string): MessageBannerProps => {
  return { messageBarType: messageBarType, messageContent: messageContent };
};

const isMessageOverflow = (messageContent?: string): boolean => {
  return (messageContent && document.documentElement.clientHeight - messageContent.length < 0) as boolean;
};

export const MessageBanner = (props: MessageBannerProps): JSX.Element => {
  const isTruncated = isMessageOverflow(props.messageContent);

  return (
    <>
      <MessageBar
        messageBarType={props.messageBarType}
        isMultiline={true}
        onDismiss={() => {
          if (props.handleQuit) props.handleQuit();
        }}
        dismissButtonAriaLabel="Close"
        overflowButtonAriaLabel={isTruncated ? 'See more' : ''}
        styles={{ root: { fontSize: '1rem' } }}
      >
        <div style={{ fontSize: '1.3rem', textAlign: 'left', whiteSpace: 'pre-wrap', lineHeight: 'normal' }}>
          {props.messageContent}
        </div>
      </MessageBar>
    </>
  );
};
