import { appSettings } from '../appSettings';
import { Settings } from '../types/settings';

export const createASettings = async (token: string, settingsToCreate: Settings) => {
  const requestUrl = `${appSettings.host.artAuctionServerHost}/api/settings`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: JSON.stringify(settingsToCreate)
  };

  const response = await fetch(requestUrl, requestOptions);

  return response.json();
};

export const getASettings = async () => {
  const requestUrl = `${appSettings.host.artAuctionServerHost}/api/settings`;

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };

  const response = await fetch(requestUrl, requestOptions);

  return response.json();
};
