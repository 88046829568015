import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LotLanding from './pages/bidder/LotLanding';
import Dashboard from './pages/registrar/Dashboard';
import RegistrarAuth from './pages/registrar/RegistrarAuth';
import ProtectedRoute from './ProtectedRoute';
import BidderAuth from './pages/bidder/BidderAuth';
import LotWinList from './pages/bidder/LotWinList';
import LotInfo from './pages/bidder/LotInfo';
import Projector from './pages/projector/Projector';
import LotBid from './pages/bidder/LotBid';
import OfferBoard from './components/registrar/OfferBoard';
import BidSuccess from './pages/bidder/BidSuccess';
import NotFound from './pages/error/NotFound';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/bidder/auth" element={<BidderAuth />} />
          <Route
            path="/"
            element={<ProtectedRoute routeType="bidder" component={LotLanding} authenticationPath="/bidder/auth" />}
          />
          <Route
            path="/bidder"
            element={<ProtectedRoute routeType="bidder" component={LotLanding} authenticationPath="/bidder/auth" />}
          />
          <Route
            path="/bidder/lots"
            element={<ProtectedRoute routeType="bidder" component={LotLanding} authenticationPath="/bidder/auth" />}
          />
          <Route
            path="/bidder/lots/:lotId"
            element={<ProtectedRoute routeType="bidder" component={LotInfo} authenticationPath="/bidder/auth" />}
          />
          <Route
            path="/bidder/lots/:lotId/bid"
            element={<ProtectedRoute routeType="bidder" component={LotBid} authenticationPath="/bidder/auth" />}
          />
          <Route
            path="/bidder/bidsuccess"
            element={<ProtectedRoute routeType="bidder" component={BidSuccess} authenticationPath="/bidder/auth" />}
          />
          <Route
            path="/bidder/winlist"
            element={<ProtectedRoute routeType="bidder" component={LotWinList} authenticationPath="/bidder/auth" />}
          />
          <Route path="/registrar/auth" element={<RegistrarAuth />} />
          <Route
            path="/registrar"
            element={
              <ProtectedRoute routeType="registrar" component={Dashboard} authenticationPath="/registrar/auth" />
            }
          />
          <Route
            path="/registrar/dashboard"
            element={
              <ProtectedRoute routeType="registrar" component={Dashboard} authenticationPath="/registrar/auth" />
            }
          />
          <Route
            path="/registrar/:lotId/offerboard"
            element={
              <ProtectedRoute routeType="registrar" component={OfferBoard} authenticationPath="/registrar/auth" />
            }
          />
          <Route path="/projector" element={<Projector />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
