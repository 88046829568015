import { getTheme, IStackTokens, mergeStyles } from '@fluentui/react';

const palette = getTheme().palette;

const fileIconCell = mergeStyles({
  textAlign: 'center',
  selectors: {
    '&:before': {
      content: '.',
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%',
      width: 0,
      visibility: 'hidden'
    }
  }
});

const fileIconHeaderIcon = mergeStyles({
  padding: '0 0.5rem 0 0.5rem',
  fontSize: '1rem'
});

const fileIconImg = mergeStyles({
  verticalAlign: 'middle',
  maxHeight: '1rem',
  maxWidth: '1rem'
});

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 5,
  padding: '0.625rem 0'
};

const dashboardListContainer = mergeStyles({
  margin: '0 10%'
});

const dashboardListTitle = mergeStyles({
  fontSize: '1.75rem',
  fontWeight: '400'
});

const dashboardListWrapStackTokens: IStackTokens = { childrenGap: 10 };

const separatorStyles = {
  root: {
    color: palette.black,
    width: '0.0625rem'
  }
};

export {
  fileIconCell,
  fileIconHeaderIcon,
  fileIconImg,
  itemAlignmentsStackTokens,
  dashboardListContainer,
  dashboardListTitle,
  dashboardListWrapStackTokens,
  separatorStyles
};
