import React, { FC } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';
import { appSettings } from './appSettings';

type ProtectedRouteType = {
  routeType: string;
  component: React.FC;
  authenticationPath: string;
};

const ProtectedRoute: FC<ProtectedRouteType> = (props: ProtectedRouteType) => {
  const cookieName =
    props.routeType == 'registrar' ? appSettings.registrar.userInfoCookieName : appSettings.bidder.userInfoCookieName;
  const [cookies] = useCookies([cookieName]);
  const token = cookies[cookieName]?.token;
  const isAuthenticated = (token && token != 'undefined') as boolean;

  if (isAuthenticated) return <props.component />;

  return <Navigate to={props.authenticationPath} />;
};

export default ProtectedRoute;
