import { Modal } from '@fluentui/react';
import { useState } from 'react';
import { Lot } from '../../../types/lot';
import { dialogContainerStyles } from '../../styles/Common.styles';

type LotBulkImportDialogProps = {
  isOpen: boolean;
  refreshKey: number;
  onDismiss: () => void;
  resetFields: () => void;
  setRefreshKey: (refreshKey: number) => void;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export default (props: LotBulkImportDialogProps): JSX.Element => {
  const [guid, setGuid] = useState<string | undefined>();
  const [lots, setLots] = useState<Lot[]>();

  const dismiss = (): void => {
    if (guid) {
      props.resetFields();
    }
    props.onDismiss();
  };

  return (
    <>
      <Modal isOpen={props.isOpen} onDismiss={dismiss} styles={dialogContainerStyles}>
        <span>Developing</span>
      </Modal>
    </>
  );
};
