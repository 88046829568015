import { getTheme, Stack } from '@fluentui/react';
import { appSettings } from '../../appSettings';
import { Offer } from '../../types/offer';
import { formatMoneyString } from '../../utils/utils';
import { text } from 'stream/consumers';

const palette = getTheme().palette;
const lightYellowRGBColorForMoneyChoice = `rgb(${appSettings.yellowRGBColorForMoneyChoice}, 0.3)`;
const lightMagentaRGBColorForOpportunityChoice = `rgb(${appSettings.magentaRGBColorForOpportunityChoice}, 0.3)`;
const lightBlueRGBColorForUnderstandingChoice = `rgb(${appSettings.blueRGBColorForUnderstandingChoice}, 0.3)`;
const lightGreenRGBColorForExchangeChoice = `rgb(${appSettings.greenRGBColorForExchangeChoice}, 0.3)`;

type OfferBoardRowProps = {
  offer?: Offer;
};

const tdStyle = {
  width: '100%',
  height: '6.9vh',
  margin: '0',
  padding: '0',
  overflow: 'auto'
};

export default (props: OfferBoardRowProps): JSX.Element => {
  return (
    <tr>
      <td style={{ fontFamily: 'Harbourfront Body Bold' }}>
        {/* <Stack horizontal horizontalAlign="space-between" verticalAlign="center"> */}
        <div style={tdStyle}> {props.offer?.bidder?.bidderName ?? ''} </div>
        {/* </Stack> */}
      </td>
      <td
        style={{
          backgroundColor: `${
            props.offer?.money && props.offer?.money !== '0' ? lightYellowRGBColorForMoneyChoice : palette.white
          }`
        }}
      >
        <div style={tdStyle}>
          {formatMoneyString(props.offer?.money ?? '') === '$0' ? '' : formatMoneyString(props.offer?.money ?? '')}
        </div>
      </td>
      <td
        style={{
          backgroundColor: `${props.offer?.opportunity ? lightMagentaRGBColorForOpportunityChoice : palette.white}`
        }}
      >
        <div style={tdStyle}>{props.offer?.opportunity ?? ''}</div>
      </td>
      <td
        style={{
          backgroundColor: `${props.offer?.understanding ? lightBlueRGBColorForUnderstandingChoice : palette.white}`
        }}
      >
        <div style={tdStyle}>{props.offer?.understanding ?? ''}</div>
      </td>
      <td
        style={{
          backgroundColor: `${props.offer?.exchange ? lightGreenRGBColorForExchangeChoice : palette.white}`
        }}
      >
        <div style={tdStyle}>{props.offer?.exchange ?? ''}</div>
      </td>
    </tr>
  );
};
