import { getTheme, IStackTokens, mergeStyles } from '@fluentui/react';

const palette = getTheme().palette;

const headerShadow = 'rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px';

const headerContainer = mergeStyles({
  color: palette.white,
  backgroundColor: palette.black,
  boxShadow: headerShadow
});

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 5,
  padding: '0.525rem 0.825rem'
};

const headerIconButtonContainer = mergeStyles({
  width: '5.3125rem'
});

const headerTitleContainer = mergeStyles({
  fontSize: '1.2rem',
  fontFamily: 'Harbourfront Header',
  marginLeft: '0.25rem'
});

const headerButton = mergeStyles({
  border: 'none',
  color: palette.white,
  backgroundColor: palette.black,
  selectors: {
    ':hover': {
      color: palette.white,
      backgroundColor: palette.black
    },
    ':active': {
      color: palette.white,
      backgroundColor: palette.black
    }
  }
});

const headerIconButton = mergeStyles({
  color: palette.white,
  selectors: {
    ':hover': {
      color: palette.white,
      backgroundColor: palette.black
    },
    ':active': {
      color: palette.white,
      backgroundColor: palette.black
    }
  }
});

const settingsStackTokens: IStackTokens = {
  childrenGap: 15,
  padding: '1rem 0 1rem 0'
};

export {
  headerContainer,
  itemAlignmentsStackTokens,
  headerButton,
  headerIconButton,
  headerIconButtonContainer,
  headerTitleContainer,
  settingsStackTokens
};
