import { appSettings } from '../appSettings';
import { HttpStatusCode } from '../types/HttpStatusCode';
import { Lot } from '../types/lot';

export const createALot = async (token: string, lot: Lot) => {
  const requestUrl = `${appSettings.host.artAuctionServerHost}/api/lots`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: JSON.stringify({
      lotIndex: lot.lotIndex,
      title: lot.title,
      description: lot.description,
      artists: lot.artists,
      countries: lot.countries,
      imgUrl: lot.imgUrl
    })
  };

  const response = await fetch(requestUrl, requestOptions);

  return response.json();
};

export const getASelectedLot = async (token: string, lotId?: string) => {
  if (lotId) {
    const requestUrl = `${appSettings.host.artAuctionServerHost}/api/lots/${lotId}`;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    };
    const response = await fetch(requestUrl, requestOptions);

    return response.json();
  } else {
    return { code: HttpStatusCode.BAD_REQUEST, message: 'The selected lot ID is null.' };
  }
};

export const getAllLots = async (token: string) => {
  const requestUrl = `${appSettings.host.artAuctionServerHost}/api/lots`;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  };

  const response = await fetch(requestUrl, requestOptions);

  return response.json();
};

export const updateALotById = async (token: string, lotId: string, lotUpdateInfo: Lot) => {
  if (lotId) {
    const requestUrl = `${appSettings.host.artAuctionServerHost}/api/lots/${lotId}`;
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(lotUpdateInfo)
    };
    const response = await fetch(requestUrl, requestOptions);

    return response.json();
  } else {
    return { code: HttpStatusCode.BAD_REQUEST, message: 'The selected lot ID is null.' };
  }
};

export const deleteASelectedLot = async (token: string, lotId: string) => {
  const requestUrl = `${appSettings.host.artAuctionServerHost}/api/lots/${lotId}`;
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  };

  const response = await fetch(requestUrl, requestOptions);

  if (response.status !== HttpStatusCode.NO_CONTENT) {
    return await response.json();
  }

  return { code: HttpStatusCode.NO_CONTENT };
};
