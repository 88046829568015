import { CommandBarButton, getTheme, IButtonStyles, mergeStyles, Stack } from '@fluentui/react';
import { appSettings } from '../../appSettings';
import { ReactComponent as ExchangeIconSVG } from '../../assets/icons/exchange-icon.svg';
import { ReactComponent as MoneyIconSVG } from '../../assets/icons/money-icon.svg';
import { ReactComponent as OpportunityIconSVG } from '../../assets/icons/opportunity-icon.svg';
import { ReactComponent as UnderstandIconSVG } from '../../assets/icons/understanding-icon.svg';
import { Offer } from '../../types/offer';
import { BidType } from '../../types/bidType';
import { convertCurrencyToNumberString, formatMoneyString } from '../../utils/utils';

const palette = getTheme().palette;

const BidOptions = {
  money: 'money',
  opportunity: 'opportunity',
  understanding: 'understanding',
  exchange: 'exchange'
} as const;

type BidChoicesProps = {
  offer: Offer;
  isBidded: boolean;
  isValidBid?: boolean;
  isMoneySelected: boolean;
  isOpportunitySelected: boolean;
  isUnderstandingSelected: boolean;
  isExchangeSelected: boolean;
  setOffer: (offer: Offer) => void;
  setIsMoneySelected: (isMoneySelected: boolean) => void;
  setIsOpportunitySelected: (isOpportunitySelected: boolean) => void;
  setIsUnderstandingSelected: (isUnderstandingSelected: boolean) => void;
  setIsExchangeSelected: (isExchangeSelected: boolean) => void;
  removeLocalStorage: () => void;
};

const bidStatement = 'By clicking the BID button below you agree to submit your offer.';
const bidStatement_chinese = '按下方 BID 按鈕以同意提交您的競投.';

export default (props: BidChoicesProps): JSX.Element => {
  const changeSelectedChoicestate = (bidType: BidType, isSelected: boolean) => {
    bidType === BidOptions.opportunity
      ? props.setIsOpportunitySelected(isSelected)
      : bidType === BidOptions.understanding
      ? props.setIsUnderstandingSelected(isSelected)
      : props.setIsExchangeSelected(isSelected);
  };

  const handleBidChoicesChange = (bidType: BidType, event?: React.ChangeEvent<HTMLInputElement>) => {
    if (bidType === BidOptions.money) {
      const newAmount = convertCurrencyToNumberString(event?.target.value ?? '0');
      if (newAmount && newAmount !== '0') {
        const offerWillBeUpdated: Offer = props.offer;
        offerWillBeUpdated[bidType] = newAmount;
        props.setOffer({ ...offerWillBeUpdated });
        props.setIsMoneySelected(true);
      } else if (bidType in props.offer) {
        const offerWillBeUpdated: Offer = props.offer;
        delete offerWillBeUpdated[bidType];
        props.setOffer(offerWillBeUpdated);
        props.setIsMoneySelected(false);
      }
    } else {
      if (bidType in props.offer) {
        const offerWillBeUpdated: Offer = props.offer;
        delete offerWillBeUpdated[bidType];
        props.setOffer(offerWillBeUpdated);
        changeSelectedChoicestate(bidType, false);
      } else {
        const offerWillBeUpdated: Offer = props.offer;
        offerWillBeUpdated[bidType] = ' ';
        props.setOffer(offerWillBeUpdated);
        changeSelectedChoicestate(bidType, true);
      }
    }
  };

  const isDisplayedAfterBidding = (isSelected: boolean): boolean => {
    return !props.isBidded ? true : isSelected ? true : false;
  };

  return (
    <Stack tokens={{ childrenGap: 20 }} className={bidChoicesContainer}>
      {isDisplayedAfterBidding(props.isMoneySelected) && (
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={bidChoiceSubContainer(props.isMoneySelected)}
        >
          <MoneyIconSVG className={bidChoiceMoneyIcon(props.isMoneySelected)} />
          <input
            type="text"
            placeholder="$"
            value={formatMoneyString(props.offer.money || '0')}
            disabled={props.isBidded}
            className={bidChoiceTextfield(props.isMoneySelected)}
            onChange={(event) => handleBidChoicesChange(BidOptions.money, event)}
          />
        </Stack>
      )}
      {isDisplayedAfterBidding(props.isOpportunitySelected) && (
        <CommandBarButton
          iconProps={{ iconName: 'HfCOpportunity' }}
          text="機會 Opportunity"
          disabled={props.isBidded}
          styles={bidChoiceButtonStyles}
          className={opportunityButtonSelected(props.isOpportunitySelected)}
          onClick={() => handleBidChoicesChange(BidOptions.opportunity)}
        />
      )}
      {isDisplayedAfterBidding(props.isUnderstandingSelected) && (
        <CommandBarButton
          iconProps={{ iconName: 'HfCUnderstand' }}
          text="理解 Understanding"
          disabled={props.isBidded}
          styles={bidChoiceButtonStyles}
          className={understandingButtonSelected(props.isUnderstandingSelected)}
          onClick={() => handleBidChoicesChange(BidOptions.understanding)}
        />
      )}
      {isDisplayedAfterBidding(props.isExchangeSelected) && (
        <CommandBarButton
          iconProps={{ iconName: 'HfCExchange' }}
          text="交換 Exchange"
          disabled={props.isBidded}
          styles={bidChoiceButtonStyles}
          className={exchangeButtonSelected(props.isExchangeSelected)}
          onClick={() => handleBidChoicesChange(BidOptions.exchange)}
        />
      )}
      <Stack className={bidStatementContainer}>
        {!props.isBidded && !props.isValidBid && <p className={bidStatementClass}>{bidStatement}</p>}
        {!props.isBidded && !props.isValidBid && <p className={bidStatementClass}>{bidStatement_chinese}</p>}
      </Stack>
      {props.isBidded && (
        <Stack verticalAlign="center" horizontalAlign="center" className={bidSubmittedContainer}>
          {props.isMoneySelected && (
            <MoneyIconSVG
              style={{
                width: '4rem',
                zIndex: 0,
                filter: 'invert(84%) sepia(16%) saturate(1686%) hue-rotate(351deg) brightness(94%) contrast(95%)'
              }}
            />
          )}
          {props.isOpportunitySelected && (
            <OpportunityIconSVG
              style={{
                margin: '-2.8rem 3.6rem 0 0',
                width: '4rem',
                zIndex: -1,
                filter: 'invert(16%) sepia(86%) saturate(7458%) hue-rotate(334deg) brightness(101%) contrast(104%)'
              }}
            />
          )}
          {props.isUnderstandingSelected && (
            <UnderstandIconSVG
              style={{
                margin: '-4.2rem -1.8rem 0 0',
                width: '4rem',
                zIndex: -2,
                filter: 'invert(39%) sepia(38%) saturate(698%) hue-rotate(159deg) brightness(94%) contrast(96%)'
              }}
            />
          )}
          {props.isExchangeSelected && (
            <ExchangeIconSVG
              style={{
                margin: '-3.1rem 0 0 0',
                width: '4rem',
                zIndex: -3,
                filter: 'invert(32%) sepia(32%) saturate(6591%) hue-rotate(77deg) brightness(93%) contrast(94%)'
              }}
            />
          )}
          <p style={{ marginBottom: 0, textTransform: 'uppercase' }}>提交成功 Bid Submitted</p>
          <p style={{ marginTop: 0, textTransform: 'uppercase' }}>Good Luck</p>
          <input
            type="password"
            placeholder="Request to reset"
            onChange={(event) => {
              if (event.target.value === 'pcabidreset') {
                props.removeLocalStorage();
                //window.location.reload();
              }
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

const bidChoicesContainer = mergeStyles({
  padding: '1.25rem'
});

const bidChoiceSubContainer = (isSelected: boolean) =>
  mergeStyles({
    height: '3.3rem',
    background: isSelected ? `rgb(${appSettings.yellowRGBColorForMoneyChoice})` : palette.neutralLight
  });

const bidChoiceTextfield = (isSelected: boolean) =>
  mergeStyles({
    height: '3.2rem',
    width: '100%',
    border: 'none',
    color: isSelected ? palette.white : palette.black,
    background: isSelected ? `rgb(${appSettings.yellowRGBColorForMoneyChoice})` : palette.neutralLight,
    textAlign: 'center',
    selectors: {
      ':focus': {
        outline: 'none'
      }
    }
  });

const bidChoiceButtonStyles: IButtonStyles = {
  icon: { fontSize: 32, width: '2rem', height: '2rem', padding: '0.2rem 0.4rem 0 1.8rem' },
  root: {
    height: '3.2rem',
    fontSize: '1.125rem',
    border: 'none',
    textTransform: 'uppercase',
    textAlign: 'center'
    // selectors: {
    //   ':hover .ms-Button-icon': {
    //     color: palette.white
    //   },
    //   ':active .ms-Button-icon': {
    //     color: palette.white
    //   }
    // }
  }
  //   rootHovered: { backgroundColor: 'black' },
  //   rootPressed: { backgroundColor: 'black' }
};

const bidStatementClass = mergeStyles({
  fontSize: '1.5rem',
  textAlign: 'justify'
});
const bidStatementContainer = mergeStyles({
  marginTop: '1.2rem'
});

const bidSubmittedContainer = mergeStyles({
  position: 'absolute',
  top: '57%',
  left: '50%',
  marginLeft: '-3.125rem'
});

const opportunityButtonSelected = (isSelected: boolean) =>
  mergeStyles({
    color: isSelected ? palette.white : palette.black,
    background: isSelected ? `rgb(${appSettings.magentaRGBColorForOpportunityChoice})` : palette.neutralLight,
    selectors: {
      'i.ms-Icon': {
        filter: isSelected
          ? 'invert(100%) sepia(5%) saturate(19%) hue-rotate(215deg) brightness(107%) contrast(106%)'
          : ''
      },
      ':hover': {
        color: isSelected ? palette.white : palette.black,
        background: isSelected ? `rgb(${appSettings.magentaRGBColorForOpportunityChoice})` : palette.neutralLight
      },
      ':active': {
        color: isSelected ? palette.white : palette.black,
        background: isSelected ? `rgb(${appSettings.magentaRGBColorForOpportunityChoice})` : palette.neutralLight
      },
      ':focus': {
        color: isSelected ? palette.white : palette.black,
        background: isSelected ? `rgb(${appSettings.magentaRGBColorForOpportunityChoice})` : palette.neutralLight
      }
    }
  });

const understandingButtonSelected = (isSelected: boolean) =>
  mergeStyles({
    color: isSelected ? palette.white : palette.black,
    background: isSelected ? `rgb(${appSettings.blueRGBColorForUnderstandingChoice})` : palette.neutralLight,
    selectors: {
      'i.ms-Icon': {
        filter: isSelected
          ? 'invert(100%) sepia(5%) saturate(19%) hue-rotate(215deg) brightness(107%) contrast(106%)'
          : ''
      },
      ':hover': {
        color: isSelected ? palette.white : palette.black,
        background: isSelected ? `rgb(${appSettings.blueRGBColorForUnderstandingChoice})` : palette.neutralLight
      },
      ':active': {
        color: isSelected ? palette.white : palette.black,
        background: isSelected ? `rgb(${appSettings.blueRGBColorForUnderstandingChoice})` : palette.neutralLight
      },
      ':focus': {
        color: isSelected ? palette.white : palette.black,
        background: isSelected ? `rgb(${appSettings.blueRGBColorForUnderstandingChoice})` : palette.neutralLight
      }
    }
  });

const exchangeButtonSelected = (isSelected: boolean) =>
  mergeStyles({
    color: isSelected ? palette.white : palette.black,
    background: isSelected ? `rgb(${appSettings.greenRGBColorForExchangeChoice})` : palette.neutralLight,
    selectors: {
      'i.ms-Icon': {
        filter: isSelected
          ? 'invert(100%) sepia(5%) saturate(19%) hue-rotate(215deg) brightness(107%) contrast(106%)'
          : ''
      },
      ':hover': {
        color: isSelected ? palette.white : palette.black,
        background: isSelected ? `rgb(${appSettings.greenRGBColorForExchangeChoice})` : palette.neutralLight
      },
      ':active': {
        color: isSelected ? palette.white : palette.black,
        background: isSelected ? `rgb(${appSettings.greenRGBColorForExchangeChoice})` : palette.neutralLight
      },
      ':focus': {
        color: isSelected ? palette.white : palette.black,
        background: isSelected ? `rgb(${appSettings.greenRGBColorForExchangeChoice})` : palette.neutralLight
      }
    }
  });

const bidChoiceMoneyIcon = (isSelected: boolean) =>
  mergeStyles({
    width: '2rem',
    padding: '0 2.4rem 0 2.4rem',
    filter: isSelected ? 'invert(100%) sepia(5%) saturate(19%) hue-rotate(215deg) brightness(107%) contrast(106%)' : ''
  });
