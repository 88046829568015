import { useState } from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const IsLoadingHOC = (WrappedComponent: any, LoadingComponent: any) => {
  const HighOrderComponent = (props: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const setLoadingState = (isComponentLoading: boolean) => {
      setIsLoading(isComponentLoading);
    };

    return (
      <>
        <WrappedComponent {...props} setLoading={setLoadingState} />
        {isLoading && <LoadingComponent />}
      </>
    );
  };

  return HighOrderComponent;
};
