import { getTheme } from '@fluentui/react';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const palette = getTheme().palette;

const baseStyle: CSSProperties = {
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '0.475rem',
  padding: '1.25rem',
  borderWidth: '0.125rem',
  borderRadius: '0.125rem',
  borderColor: palette.black,
  borderStyle: 'dashed',
  backgroundColor: palette.white,
  color: 'rgb(50, 49, 48)',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const acceptStyle = {
  borderColor: palette.green
};

const rejectStyle = {
  borderColor: palette.red
};

const thumbsContainer: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb: CSSProperties = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

/* eslint-disable @typescript-eslint/no-explicit-any */
type FileUploadZoneProps = {
  handleLotImage: (lotImage: any) => void;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export default (props: FileUploadZoneProps): JSX.Element => {
  const [files, setFiles] = useState<any[]>([]);
  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': []
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
      props.handleLotImage(acceptedFiles[0]);
    }
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragAccept, isDragReject]
  );

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <>
      <div style={{ marginTop: '0.375rem' }}>
        <span style={{ fontSize: '0.875rem', fontWeight: '600', color: `${palette.black}` }}>
          Artwork Image <span style={{ color: 'rgb(164, 38, 44)' }}>*</span>
        </span>
      </div>
      <section className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Drag & drop an artwork image here, or click to select an artwork image</p>
          <em>(Only image files will be accepted)</em>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </section>
    </>
  );
};
