import { appSettings } from '../../appSettings';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import BidderSignUpScreen from '../../components/bidder/BidderSignUpScreen';
import { useCookies } from 'react-cookie';

export default (): JSX.Element => {
  const [cookies, setCookie, removeCookie] = useCookies([appSettings.bidder.userInfoCookieName]);
  const navigate = useNavigate();

  useEffect(() => {
    const cookie = cookies[appSettings.bidder.userInfoCookieName];
    const bidderToken = cookie?.token;
    const tokenExists: boolean = (bidderToken && bidderToken != 'undefined') as boolean;
    if (tokenExists) {
      navigate('/bidder/lots');
    } else if (!window.location.href.includes('www')) {
      window.location.href = appSettings.host.artAuctionClientHost + '/bidder/auth';
    }
  }, []);

  return (
    <>
      <BidderSignUpScreen />
    </>
  );
};
