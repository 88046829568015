import { getTheme, ITextFieldStyles, TextField } from '@fluentui/react';

const palette = getTheme().palette;

type AccessCodeProps = {
  accessCode?: string;
  setAccessCode: (accessCode?: string) => void;
};

export default (props: AccessCodeProps): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <p style={{ color: palette.black, fontSize: '1rem', fontWeight: 600, marginBottom: '0.2rem' }}>Bidders' Access</p>
      <TextField
        label="Code:"
        underlined
        placeholder="Enter an access code for bidders"
        styles={accessCodeTextFieldStyles}
        onChange={(_, value): void => {
          props.setAccessCode(value);
        }}
        value={props.accessCode ?? ''}
        maxLength={50}
      />
    </div>
  );
};

const accessCodeTextFieldStyles: Partial<ITextFieldStyles> = {
  subComponentStyles: {
    label: {
      root: {
        padding: '5px 0'
      }
    }
  }
};
