import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  getTheme,
  IconButton,
  ITextFieldStyles,
  mergeStyles,
  PrimaryButton,
  Stack,
  TextField
} from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { appSettings } from '../../appSettings';
import Events from '../../Events';
import { RegistrarCookie } from '../../types/registrarCookie';
import { LotStatus } from '../../types/lotStatus';
import { Offer } from '../../types/offer';
import { registrarSocket } from '../../utils/clientSockets';
import { convertCurrencyToNumberString, formatMoneyString } from '../../utils/utils';
import { updateALotById } from '../../services/lot.service';
import { getASelectedBidder, updateABidderById } from '../../services/bidder.service';

const palette = getTheme().palette;
const lightYellowRGBColorForMoneyChoice = `rgb(${appSettings.yellowRGBColorForMoneyChoice}, 0.3)`;
const lightMagentaRGBColorForOpportunityChoice = `rgb(${appSettings.magentaRGBColorForOpportunityChoice}, 0.3)`;
const lightBlueRGBColorForUnderstandingChoice = `rgb(${appSettings.blueRGBColorForUnderstandingChoice}, 0.3)`;
const lightGreenRGBColorForExchangeChoice = `rgb(${appSettings.greenRGBColorForExchangeChoice}, 0.3)`;

const chooseWinnerDialogContentProps = {
  type: DialogType.normal,
  title: 'Choose Winner',
  subText: 'Are you sure you want to choose the bidder as a winner.'
};

const deleteDialogContentProps = {
  type: DialogType.normal,
  title: 'Delete Offer',
  subText: "Are you sure you want to delete the selected bidder's offer."
};

/* eslint-disable @typescript-eslint/no-explicit-any */
type OfferBoardRowProps = {
  lot?: any;
  setOfferNumberOnBoard?: any;
  setOfferList?: any;
  setRows?: any;
  offer?: Offer;
};

export default (props: OfferBoardRowProps): JSX.Element => {
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');
  const [hideChooseWinnerDialog, { toggle: toggleHideChooseWinnerDialog }] = useBoolean(true);
  const [hideDeleteDialog, { toggle: toggleHideDeleteDialog }] = useBoolean(true);
  const [isWinnerChosen, setIsWinnerChosen] = useState<boolean>(false);
  const [moneyChoiceValue, setMoneyChoiceValue] = useState<string | undefined>();
  const [opportunityChoiceValue, setOpportunityChoiceValue] = useState<string | undefined>();
  const [understandingChoiceValue, setUnderstandingChoiceValue] = useState<string | undefined>();
  const [exchangeChoiceValue, setExchangeChoiceValue] = useState<string | undefined>();
  const [cookies] = useCookies([appSettings.registrar.userInfoCookieName]);
  const registrarToken = (cookies[appSettings.registrar.userInfoCookieName] as RegistrarCookie)?.token;

  const confirmationModelProps = useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: { main: { maxWidth: 450 } }
    }),
    [labelId, subTextId]
  );

  useEffect(() => {
    if (props.offer) {
      props.offer.money && setMoneyChoiceValue(props.offer.money);
      props.offer.opportunity && setOpportunityChoiceValue(props.offer.opportunity);
      props.offer.understanding && setUnderstandingChoiceValue(props.offer.understanding);
      props.offer.exchange && setExchangeChoiceValue(props.offer.exchange);
    }
  }, [props.offer]);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const deleteOffer = (event: any, bidderName?: string) => {
    props.setOfferList((oldOfferList: Offer[]) => {
      const offerToDeleteIndex: number = oldOfferList.findIndex((offer: Offer) => {
        if (offer.bidder?.bidderName === bidderName) {
          return true;
        }
        return false;
      });

      props.setRows((oldRowList: JSX.Element[]) => {
        const newRowList: JSX.Element[] = [...oldRowList];
        newRowList.splice(offerToDeleteIndex, 1);
        return newRowList;
      });

      props.setOfferNumberOnBoard((oldOfferNumberOnBoard: number) => {
        return oldOfferNumberOnBoard - 1;
      });

      const newOfferList: Offer[] = [...oldOfferList];
      newOfferList.splice(offerToDeleteIndex, 1);
      return newOfferList;
    });
    registrarSocket.emit(Events.DELETE_OFFER, bidderName);
    toggleHideDeleteDialog();
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const chooseWinner = async (event: any, offer: Offer) => {
    // Update Lot info -> winner
    const lotId = props.lot && props.lot['_id'];
    const lotUpdateInfo = { status: 'bidded' as LotStatus, winner: offer.bidder?.bidderName };
    const lotUpdated = await updateALotById(registrarToken, lotId, lotUpdateInfo);

    // Update Bidder info -> win lot
    const bidderId = offer.bidder?.bidderId ?? '';
    const bidderInfo = await getASelectedBidder(registrarToken, bidderId);
    const lotsWon = (bidderInfo && bidderInfo.lots) ?? [];
    lotsWon.push(offer.lot);
    const bidderUpdateInfo = { lots: lotsWon };
    const bidderUpdated = await updateABidderById(registrarToken, bidderId, bidderUpdateInfo);

    setIsWinnerChosen(true);
    registrarSocket.emit(Events.CHOOSE_WINNER, offer);
  };

  const onChangeMoneyChoiceValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setMoneyChoiceValue(convertCurrencyToNumberString(newValue ?? ''));
    const newOffer: Offer = {
      lot: props.lot,
      bidder: props.offer?.bidder,
      money: convertCurrencyToNumberString(newValue ?? ''),
      opportunity: opportunityChoiceValue,
      understanding: understandingChoiceValue,
      exchange: exchangeChoiceValue
    };
    registrarSocket.emit(Events.EDIT_Offer, newOffer);
  };

  const onChangeOpportunityChoiceValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setOpportunityChoiceValue(newValue ?? '');
    const newOffer: Offer = {
      lot: props.lot,
      bidder: props.offer?.bidder,
      money: moneyChoiceValue,
      opportunity: newValue ?? '',
      understanding: understandingChoiceValue,
      exchange: exchangeChoiceValue
    };
    registrarSocket.emit(Events.EDIT_Offer, newOffer);
  };

  const onChangeUnderstandingChoiceValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setUnderstandingChoiceValue(newValue ?? '');
    const newOffer: Offer = {
      lot: props.lot,
      bidder: props.offer?.bidder,
      money: moneyChoiceValue,
      opportunity: opportunityChoiceValue,
      understanding: newValue ?? '',
      exchange: exchangeChoiceValue
    };
    registrarSocket.emit(Events.EDIT_Offer, newOffer);
  };

  const onChangeExchangeChoiceValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setExchangeChoiceValue(newValue ?? '');
    const newOffer: Offer = {
      lot: props.lot,
      bidder: props.offer?.bidder,
      money: moneyChoiceValue,
      opportunity: opportunityChoiceValue,
      understanding: understandingChoiceValue,
      exchange: newValue ?? ''
    };
    registrarSocket.emit(Events.EDIT_Offer, newOffer);
  };

  return (
    <tr>
      <td>
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
          <span>{props.offer?.bidder?.bidderName ?? ''}</span>
          {props.offer && (
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ childrenGap: 20 }}>
              {isWinnerChosen ? (
                <IconButton
                  iconProps={{ iconName: 'CrownSolid' }}
                  title="Winner"
                  ariaLabel="Winner"
                  styles={winnerStyles}
                />
              ) : (
                <>
                  <IconButton
                    iconProps={{ iconName: 'Crown' }}
                    title="Choose Winner"
                    ariaLabel="Choose Winner"
                    className={actionIconButton}
                    onClick={toggleHideChooseWinnerDialog}
                  />
                  <Dialog
                    hidden={hideChooseWinnerDialog}
                    onDismiss={toggleHideChooseWinnerDialog}
                    dialogContentProps={chooseWinnerDialogContentProps}
                    modalProps={confirmationModelProps}
                  >
                    <DialogFooter>
                      <DefaultButton onClick={toggleHideChooseWinnerDialog} text="Cancel" />
                      <PrimaryButton
                        onClick={(event) =>
                          chooseWinner(event, {
                            lot: props.lot,
                            bidder: props.offer?.bidder,
                            money: moneyChoiceValue,
                            opportunity: opportunityChoiceValue,
                            understanding: understandingChoiceValue,
                            exchange: exchangeChoiceValue
                          } as Offer)
                        }
                        text="Confirm"
                      />
                    </DialogFooter>
                  </Dialog>
                  <IconButton
                    iconProps={{ iconName: 'Cancel' }}
                    title="Delete"
                    ariaLabel="Delete"
                    className={actionIconButton}
                    onClick={(event) => deleteOffer(event, props.offer?.bidder?.bidderName)}
                  />
                  {/* <Dialog
                    hidden={hideDeleteDialog}
                    onDismiss={toggleHideDeleteDialog}
                    dialogContentProps={deleteDialogContentProps}
                    modalProps={confirmationModelProps}
                  >
                    <DialogFooter>
                      <DefaultButton onClick={toggleHideDeleteDialog} text="Cancel" />
                      <PrimaryButton
                        onClick={(event) => deleteOffer(event, props.offer?.bidder?.bidderName)}
                        text="Delete"
                      />
                    </DialogFooter>
                  </Dialog> */}
                </>
              )}
            </Stack>
          )}
        </Stack>
      </td>
      <td
        style={{
          backgroundColor: `${
            moneyChoiceValue && moneyChoiceValue !== '0' ? lightYellowRGBColorForMoneyChoice : palette.white
          }`
        }}
      >
        {props.offer && (
          <TextField
            autoAdjustHeight
            borderless
            multiline
            resizable={false}
            value={formatMoneyString(moneyChoiceValue || '') === '$0' ? '' : formatMoneyString(moneyChoiceValue || '')}
            onChange={onChangeMoneyChoiceValue}
            styles={bidChoiceDefaultStyles}
          />
        )}
      </td>
      <td
        style={{
          backgroundColor: `${opportunityChoiceValue ? lightMagentaRGBColorForOpportunityChoice : palette.white}`
        }}
      >
        {props.offer && (
          <TextField
            autoAdjustHeight
            borderless
            multiline
            resizable={false}
            value={opportunityChoiceValue}
            onChange={onChangeOpportunityChoiceValue}
            styles={bidChoiceDefaultStyles}
          />
        )}
      </td>
      <td
        style={{
          backgroundColor: `${understandingChoiceValue ? lightBlueRGBColorForUnderstandingChoice : palette.white}`
        }}
      >
        {props.offer && (
          <TextField
            autoAdjustHeight
            borderless
            multiline
            resizable={false}
            value={understandingChoiceValue}
            onChange={onChangeUnderstandingChoiceValue}
            styles={bidChoiceDefaultStyles}
          />
        )}
      </td>
      <td
        style={{
          backgroundColor: `${exchangeChoiceValue ? lightGreenRGBColorForExchangeChoice : palette.white}`
        }}
      >
        {props.offer && (
          <TextField
            autoAdjustHeight
            borderless
            multiline
            resizable={false}
            value={exchangeChoiceValue}
            onChange={onChangeExchangeChoiceValue}
            styles={bidChoiceDefaultStyles}
          />
        )}
      </td>
    </tr>
  );
};

const actionIconButton = mergeStyles({
  padding: '1rem',
  color: palette.black,
  backgroundColor: palette.white,
  borderStyle: 'solid',
  borderColor: palette.white,
  borderRadius: '50%',
  cursor: 'pointer',
  opacity: 0,
  selectors: {
    ':hover': {
      color: palette.white,
      backgroundColor: palette.black
    },
    ':active': {
      color: palette.white,
      backgroundColor: palette.black
    },
    ':focus': {
      color: palette.white,
      backgroundColor: palette.black
    }
  }
});

const bidChoiceDefaultStyles: Partial<ITextFieldStyles> = {
  fieldGroup: {
    background: 'none'
  },
  field: {
    fontFamily: 'Harbourfront Body Bold',
    background: 'none'
  }
};

const winnerStyles = {
  icon: { color: palette.black },
  root: {
    backgroundColor: palette.white
  },
  rootHovered: { backgroundColor: palette.white },
  rootPressed: { backgroundColor: palette.white }
};
