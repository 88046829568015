import { Stack } from '@fluentui/react';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { appSettings } from '../../appSettings';
import BidChoices from '../../components/bidder/BidChoices';
import BidFooter from '../../components/bidder/BidFooter';
import BidHeader from '../../components/bidder/BidHeader';
import { Offer } from '../../types/offer';
import { Lot } from '../../types/lot';
import { BidderCookie } from '../../types/bidderCookie';
import { bidderSocket } from '../../utils/clientSockets';
import Events from '../../Events';
import { getASelectedLot } from '../../services/lot.service';

export default (): JSX.Element => {
  const [cookies, setCookie] = useCookies([appSettings.bidder.userInfoCookieName]);
  const bidderId = (cookies[appSettings.bidder.userInfoCookieName] as BidderCookie)?.id;
  const bidderName = (cookies[appSettings.bidder.userInfoCookieName] as BidderCookie)?.name;
  const bidderToken = (cookies[appSettings.bidder.userInfoCookieName] as BidderCookie)?.token;

  const [isMoneySelected, setIsMoneySelected] = useState<boolean>(false);
  const [isOpportunitySelected, setIsOpportunitySelected] = useState<boolean>(false);
  const [isUnderstandingSelected, setIsUnderstandingSelected] = useState<boolean>(false);
  const [isExchangeSelected, setIsExchangeSelected] = useState<boolean>(false);
  const [offer, setOffer] = useState<Offer>({ bidder: { bidderId: bidderId, bidderName: bidderName } });
  const [lot, setLot] = useState<Lot>();
  const [isBidded, setIsBidded] = useState<boolean>(false);
  const { lotId } = useParams();

  const removeLocalStorage = useCallback(() => {
    (async () => {
      const selectedLot = await getASelectedLot(bidderToken, lotId);
      await localStorage.removeItem(selectedLot['_id']);
      setIsBidded(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const selectedLot = await getASelectedLot(bidderToken, lotId);
      setLot(selectedLot);
      // check local storage if this lot is already bidded
      const isBid = localStorage.getItem(selectedLot['_id']) || false;
      setIsBidded(isBid === 'true');
    })();
  }, []);

  const submitOffer = useCallback(() => {
    bidderSocket.emit(Events.BID_LOT, offer);
    setIsBidded(true);
    (async () => {
      const selectedLot = await getASelectedLot(bidderToken, lotId);
      await localStorage.setItem(selectedLot['_id'], 'true');
    })();
  }, [offer]);

  const isValidBid = (): boolean => {
    return isMoneySelected || isOpportunitySelected || isUnderstandingSelected || isExchangeSelected;
  };

  return (
    <>
      <Stack>
        <BidHeader lotIndex={lot?.lotIndex} lotTitle={lot?.title} />
        <BidChoices
          isMoneySelected={isMoneySelected}
          isOpportunitySelected={isOpportunitySelected}
          isUnderstandingSelected={isUnderstandingSelected}
          isExchangeSelected={isExchangeSelected}
          setIsMoneySelected={setIsMoneySelected}
          setIsOpportunitySelected={setIsOpportunitySelected}
          setIsUnderstandingSelected={setIsUnderstandingSelected}
          setIsExchangeSelected={setIsExchangeSelected}
          offer={offer}
          setOffer={setOffer}
          isBidded={isBidded}
          isValidBid={isValidBid()}
          removeLocalStorage={removeLocalStorage}
        />
        <BidFooter
          isMoneySelected={isMoneySelected}
          isOpportunitySelected={isOpportunitySelected}
          isUnderstandingSelected={isUnderstandingSelected}
          isExchangeSelected={isExchangeSelected}
          isValidBid={isValidBid()}
          isBidded={isBidded}
          onSubmit={submitOffer}
          isBidding={true}
        />
      </Stack>
    </>
  );
};
