import { mergeStyles, Stack } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { appSettings } from '../../appSettings';
import { Lot } from '../../types/lot';
import { BidderCookie } from '../../types/bidderCookie';
import { getASelectedLot } from '../../services/lot.service';

export default (): JSX.Element => {
  const [lot, setLot] = useState<Lot>();
  const { lotId } = useParams();
  const [cookies] = useCookies([appSettings.bidder.userInfoCookieName]);
  const bidderToken = (cookies[appSettings.bidder.userInfoCookieName] as BidderCookie)?.token;

  useEffect(() => {
    (async () => {
      const selectedLot = await getASelectedLot(bidderToken, lotId);
      setLot(selectedLot);
    })();
  }, []);

  const lotIndexString = `拍品 Lot ${lot?.lotIndex}`;
  const artists = lot?.artists?.replace(',', ' and');

  return (
    <Stack verticalAlign="space-around" horizontalAlign="start" className={lotInfoContainer}>
      <h2 style={{ fontFamily: 'Harbourfront Header' }}>{lotIndexString}</h2>
      <img src={lot?.imgUrl} alt="Lot Image" className={lotImage} />
      <div className={lotContentContainer}>
        <section className={lotBasicInfoCOntainer}>
          <p>{lot?.title}</p>
          <p>{artists}</p>
          <p>{lot?.countries}</p>
        </section>
        <section className={lotDescriptionCOntainer}>
          <p>{lot?.description}</p>
        </section>
      </div>
    </Stack>
  );
};

const lotInfoContainer = mergeStyles({
  padding: '1.25rem'
});

const lotImage = mergeStyles({
  width: '100%',
  maxWidth: '500px'
});

const lotContentContainer = mergeStyles({
  marginTop: '1rem',
  textAlign: 'left'
});

const lotBasicInfoCOntainer = mergeStyles({
  fontFamily: 'Harbourfront Body Bold',
  fontWeight: 700
});

const lotDescriptionCOntainer = mergeStyles({
  marginTop: '2.1rem',
  textAlign: 'justify'
});
