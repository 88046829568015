import { Label, Stack, TextField } from '@fluentui/react';
import FileUploadZone from '../../common/FileUploadZone';

/* eslint-disable @typescript-eslint/no-explicit-any */
type LotCreationDialogBodyProps = {
  guid?: string;
  lotIndex?: string;
  title?: string;
  description?: string;
  artists?: string;
  countries?: string;
  setLotIndex: (lotIndex?: string) => void;
  setTitle: (title?: string) => void;
  setDescription: (description?: string) => void;
  setArtists: (artists?: string) => void;
  setCountries: (countries?: string) => void;
  handleLotImage: (lotImage: any) => void;
};

export const LotCreationDialogBody = (props: LotCreationDialogBodyProps): JSX.Element => {
  return (
    <Stack>
      {!props.guid && (
        <Stack.Item>
          <LotForm
            lotIndex={props.lotIndex}
            title={props.title}
            description={props.description}
            artists={props.artists}
            countries={props.countries}
            setLotIndex={props.setLotIndex}
            setTitle={props.setTitle}
            setDescription={props.setDescription}
            setArtists={props.setArtists}
            setCountries={props.setCountries}
            handleLotImage={props.handleLotImage}
          />
        </Stack.Item>
      )}
      {props.guid && (
        <Stack.Item>
          <LotCreated />
        </Stack.Item>
      )}
    </Stack>
  );
};

type LotFormProps = {
  lotIndex?: string;
  title?: string;
  description?: string;
  artists?: string;
  countries?: string;
  setLotIndex: (lotIndex?: string) => void;
  setTitle: (title?: string) => void;
  setDescription: (description?: string) => void;
  setArtists: (artists?: string) => void;
  setCountries: (countries?: string) => void;
  handleLotImage: (lotImage: any) => void;
};

const LotForm = (props: LotFormProps): JSX.Element => (
  <>
    <TextField
      label="Artwork Index"
      placeholder="Enter an artwork index which is a bid order"
      onChange={(_, value): void => {
        props.setLotIndex(value);
      }}
      value={props.lotIndex}
      maxLength={4}
      required
    />
    <TextField
      label="Title"
      placeholder="Enter a title for the artwork"
      onChange={(_, value): void => {
        props.setTitle(value);
      }}
      value={props.title}
      maxLength={256}
      required
    />
    <TextField
      label="Description"
      placeholder="Enter a description for the artwork"
      onChange={(_, value): void => {
        props.setDescription(value);
      }}
      value={props.description}
      multiline
      required
      rows={3}
    />
    <TextField
      label="Artists"
      placeholder={`Separate each artist with a comma, like "Karoline Hjorth, Riitta Ikonen"`}
      onChange={(_, value): void => {
        props.setArtists(value);
      }}
      value={props.artists}
      maxLength={256}
      required
    />
    <TextField
      label="Countries"
      placeholder={`Separate each artist's country with a comma, like "Norway, Finland"`}
      onChange={(_, value): void => {
        props.setCountries(value);
      }}
      value={props.countries}
      maxLength={256}
      required
    />
    <FileUploadZone handleLotImage={props.handleLotImage} />
  </>
);

const LotCreated = (): JSX.Element => (
  <>
    <Label>The artwork has been created successfully!</Label>
  </>
);
