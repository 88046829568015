import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IContextualMenuProps,
  MarqueeSelection,
  MessageBarType,
  Selection,
  SelectionMode,
  Stack
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { appSettings } from '../../appSettings';
import { deleteAllBidders, deleteASelectedBidder, getAllBidders } from '../../services/bidder.service';
import { HttpStatusCode } from '../../types/HttpStatusCode';
import { MessageBannerProps } from '../../types/messageBanner';
import { RegistrarCookie } from '../../types/registrarCookie';
import DecorationBanner from '../common/DecorationBanner';
import { createMessageBanner } from '../common/MessageBanner';
import { iconStyles } from '../styles/Common.styles';
import { itemAlignmentsStackTokens } from '../styles/Header.styles';
import { dashboardListContainer, dashboardListTitle, dashboardListWrapStackTokens } from '../styles/LotsList.styles';

/* eslint-disable @typescript-eslint/no-explicit-any */
type BidderListProps = {
  setMessageBanner: (messageBannerProps: MessageBannerProps) => void;
  signout: () => void;
};

export default (props: BidderListProps): JSX.Element => {
  const [bidders, setBidders] = useState<any[]>([]);
  const [selectedBidder, setSelectedBidder] = useState<unknown | undefined>(undefined);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [cookies] = useCookies([appSettings.registrar.userInfoCookieName]);
  const registrarToken = (cookies[appSettings.registrar.userInfoCookieName] as RegistrarCookie)?.token;

  useEffect(() => {
    (async () => {
      const response = await getAllBidders(registrarToken);

      if (!response.code) {
        setBidders(response);
      } else if (response.code === HttpStatusCode.UNAUTHORIZED) {
        props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
        setTimeout(props.signout, appSettings.defaultWaitingTimeToSignoutInMilliseconds);
      } else {
        props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
      }
    })();
  }, [refreshKey]);

  const deleteBidder = async () => {
    const bidderId: string = getSelectedBidderId()[1];

    const response = await deleteASelectedBidder(registrarToken, bidderId);

    if (response.code === HttpStatusCode.NO_CONTENT) {
      setRefreshKey(refreshKey - 1);
    } else if (response.code === HttpStatusCode.UNAUTHORIZED) {
      props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
      setTimeout(props.signout, appSettings.defaultWaitingTimeToSignoutInMilliseconds);
    } else {
      props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
    }
  };

  const deleteBidders = async () => {
    const response = await deleteAllBidders(registrarToken);

    if (response.code === HttpStatusCode.NO_CONTENT) {
      setRefreshKey(refreshKey - 1);
    } else if (response.code === HttpStatusCode.UNAUTHORIZED) {
      props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
      setTimeout(props.signout, appSettings.defaultWaitingTimeToSignoutInMilliseconds);
    } else {
      props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
    }
  };

  const deleteMenuProps: IContextualMenuProps = {
    items: [
      {
        key: 'deleteAllBidders',
        text: 'Delete All Bidders',
        iconProps: { iconName: 'Delete', styles: iconStyles },
        onClick: () => {
          deleteBidders();
        }
      }
    ]
  };

  const _selection: Selection = new Selection({
    onSelectionChanged: () => {
      setSelectedBidder(_selection.getSelection()[0]);
    }
  });

  const getSelectedBidderId = (): string[] => {
    return (selectedBidder &&
      Object.entries(selectedBidder).find(([key, value]) => {
        return key === '_id';
      })) as string[];
  };

  const _columns: IColumn[] = [
    {
      key: 'column1',
      name: 'First Name',
      fieldName: 'firstName',
      data: 'string',
      minWidth: 100,
      maxWidth: 400,
      isPadded: true,
      onRender: (item) => {
        return <span>{item.firstName}</span>;
      }
    },
    {
      key: 'column2',
      name: 'Last Name',
      fieldName: 'lastName',
      data: 'string',
      minWidth: 100,
      maxWidth: 400,
      isPadded: true,
      onRender: (item) => {
        return <span>{item.lastName}</span>;
      }
    }
  ];

  return (
    <>
      <Stack className={dashboardListContainer} tokens={dashboardListWrapStackTokens}>
        <DecorationBanner />
        <Stack horizontal horizontalAlign="space-between" tokens={itemAlignmentsStackTokens}>
          <Stack verticalAlign="center">
            <span className={dashboardListTitle}>Bidders</span>
          </Stack>
          <Stack horizontal horizontalAlign="end" tokens={itemAlignmentsStackTokens}>
            <DefaultButton
              text="Delete"
              split
              splitButtonAriaLabel="See 2 options"
              aria-roledescription="split button"
              iconProps={{ iconName: 'UserRemove' }}
              menuProps={deleteMenuProps}
              onClick={deleteBidder}
            />
          </Stack>
        </Stack>
        <Stack>
          <MarqueeSelection selection={_selection}>
            <DetailsList
              items={bidders}
              columns={_columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selection={_selection}
              checkButtonAriaLabel="select row"
              selectionMode={SelectionMode.single}
            />
          </MarqueeSelection>
        </Stack>
      </Stack>
    </>
  );
};
