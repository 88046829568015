import React, { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { appSettings } from '../../appSettings';
import Header from '../../components/registrar/Header';
import LotList from '../../components/registrar/LotList';
import BidderList from '../../components/registrar/BidderList';
import { DashboardScreen } from '../../types/DashboardScreen';
import { MessageBannerProps } from '../../types/messageBanner';
import { registrarSocket } from '../../utils/clientSockets';

export default (): JSX.Element => {
  const [messageBanner, setMessageBanner] = useState<MessageBannerProps | undefined>(undefined);
  const [screen, setScreen] = useState<string>(DashboardScreen.ARTWORKS);
  const [, , removeCookie] = useCookies([appSettings.registrar.userInfoCookieName]);
  const navigate = useNavigate();

  const signout = useCallback(() => {
    // destroy the cookie
    removeCookie(appSettings.registrar.userInfoCookieName, {
      path: '/registrar'
    });
    // redirect registrar to the auth page
    navigate('/registrar/auth');
  }, []);

  return (
    <>
      <Header
        messageBanner={messageBanner}
        setMessageBanner={setMessageBanner}
        setScreen={setScreen}
        registrarSocket={registrarSocket}
        signout={signout}
      />
      {screen === DashboardScreen.ARTWORKS ? (
        <LotList setMessageBanner={setMessageBanner} registrarSocket={registrarSocket} signout={signout} />
      ) : (
        <BidderList setMessageBanner={setMessageBanner} signout={signout} />
      )}
    </>
  );
};
