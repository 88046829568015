import { DefaultButton, MessageBarType, Stack, TextField } from '@fluentui/react';
import { useState } from 'react';
import { signupRegistrar } from '../../services/registrar.service';
import { HttpStatusCode } from '../../types/HttpStatusCode';
import { MessageBannerProps } from '../../types/messageBanner';
import { Registrar } from '../../types/registrar';
import { createMessageBanner } from '../common/MessageBanner';
import { authFormStackStyles, submitButtonStyles } from '../styles/Auth.styles';

type SignUpScreenProps = {
  changeAuthMode: () => void;
  setMessageBanner: (messageBannerProps: MessageBannerProps) => void;
};

export default (props: SignUpScreenProps): JSX.Element => {
  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [isSignedup, setIsSignedup] = useState<boolean>(false);

  const isValidSubmission = (): boolean => {
    return !firstName || !lastName || !email || !password;
  };

  const signup = async () => {
    const registrarToSignup: Registrar = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password
    };

    const response = await signupRegistrar(registrarToSignup);

    if (!response.code) {
      setIsSignedup(true);
    } else {
      props.setMessageBanner(createMessageBanner(MessageBarType.error, response.message));
    }
  };

  return (
    <>
      {isSignedup ? (
        <Stack tokens={{ childrenGap: 10, padding: 10 }} styles={authFormStackStyles}>
          <div style={{ textAlign: 'center' }}>
            You Are Signed up Successfully! <span onClick={props.changeAuthMode}>Go Sign In</span>
          </div>
        </Stack>
      ) : (
        <Stack tokens={{ childrenGap: 10, padding: 10 }} styles={authFormStackStyles}>
          <TextField
            label="First Name"
            onChange={(_, value): void => {
              setFirstName(value);
            }}
            value={firstName}
            maxLength={20}
            required
          />
          <TextField
            label="Last Name"
            onChange={(_, value): void => {
              setLastName(value);
            }}
            value={lastName}
            maxLength={20}
            required
          />
          <TextField
            label="Email"
            onChange={(_, value): void => {
              setEmail(value);
            }}
            value={email}
            maxLength={20}
            required
          />
          <TextField
            label="Password"
            type="password"
            onChange={(_, value): void => {
              setPassword(value);
            }}
            value={password}
            maxLength={20}
            required
          />
          <DefaultButton disabled={isValidSubmission()} styles={submitButtonStyles} text="Submit" onClick={signup} />
          <div style={{ textAlign: 'center' }}>
            Already registered? <span onClick={props.changeAuthMode}>Sign In</span>
          </div>
        </Stack>
      )}
    </>
  );
};
