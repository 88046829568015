import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { appSettings } from '../appSettings';

// Feature flag - disable storage feature to app if not configured
export const isBlobStorageConfigured = () => {
  return !appSettings.azureBlob.storageAccountName || !appSettings.azureBlob.sasToken ? false : true;
};

export const uploadFileToBlob = async (file: File | null): Promise<string> => {
  if (!file) return '';

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${appSettings.azureBlob.storageAccountName}.blob.core.windows.net/?${appSettings.azureBlob.sasToken}`
  );

  // get Container - full public read access
  const containerClient: ContainerClient = blobService.getContainerClient(appSettings.azureBlob.containerName);
  await containerClient.createIfNotExists({
    access: 'container'
  });

  // upload file
  await createBlobInContainer(containerClient, file);

  // return the uploaded file Url
  return `https://${appSettings.azureBlob.storageAccountName}.blob.core.windows.net/${appSettings.azureBlob.containerName}/${file.name}`;
};

const createBlobInContainer = async (containerClient: ContainerClient, file: File) => {
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  await blobClient.uploadData(file, options);
};
