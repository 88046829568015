import { mergeStyles, Stack } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { appSettings } from '../../appSettings';
import { ReactComponent as ExchangeIconSVG } from '../../assets/icons/exchange-icon.svg';
import { ReactComponent as MoneyIconSVG } from '../../assets/icons/money-icon.svg';
import { ReactComponent as OpportunityIconSVG } from '../../assets/icons/opportunity-icon.svg';
import { ReactComponent as UnderstandIconSVG } from '../../assets/icons/understanding-icon.svg';
import { Offer } from '../../types/offer';
import { bidderSocket, registrarSocket } from '../../utils/clientSockets';
import Events from '../../Events';
import { Lot } from '../../types/lot';
import OfferBoardRow from './OfferBoardRow';

type OfferBoardScreenProps = {
  lot?: Lot;
};

export default (props: OfferBoardScreenProps): JSX.Element => {
  const defaultRows: JSX.Element[] = new Array(appSettings.rowNumberOnOfferBoard).fill(<></>).map((_, index) => {
    return <OfferBoardRow key={index} />;
  });

  const [offerNumberOnBoard, setOfferNumberOnBoard] = useState<number>(0);
  const [rows, setRows] = useState<JSX.Element[]>(defaultRows);
  const [offerList, setOfferList] = useState<Offer[]>([]);

  useEffect(() => {
    const totalOfferNumber = offerList.length;
    let rowsLength = rows.length;
    if (offerNumberOnBoard < appSettings.rowNumberOnOfferBoard && offerNumberOnBoard < totalOfferNumber) {
      setRows((oldRowList: JSX.Element[]) => {
        const newRowList: JSX.Element[] = [...oldRowList];
        newRowList[offerNumberOnBoard] = (
          <OfferBoardRow key={offerNumberOnBoard} offer={offerList[offerNumberOnBoard]} />
        );
        setOfferNumberOnBoard((oldOfferNumberOnBoard: number) => {
          return oldOfferNumberOnBoard + 1;
        });
        return newRowList;
      });
      rowsLength += 1;
    }

    if (rowsLength < appSettings.rowNumberOnOfferBoard) {
      setRows((oldRowList: JSX.Element[]) => {
        const newRowList: JSX.Element[] = [...oldRowList];
        newRowList.push(<OfferBoardRow />);
        return newRowList;
      });
    }
  }, [offerList]);

  useEffect(() => {
    bidderSocket.on(Events.BID_LOT, (offer: Offer) => {
      setOfferList((oldOfferList: Offer[]) => {
        const newOfferList: Offer[] = [...oldOfferList];
        newOfferList.push(offer);
        return newOfferList;
      });
    });

    registrarSocket.on(Events.EDIT_Offer, (offerToEdit: Offer) => {
      setOfferList((oldOfferList: Offer[]) => {
        const offerToEditIndex: number = oldOfferList.findIndex((offer: Offer) => {
          if (offer.bidder?.bidderName === offerToEdit.bidder?.bidderName) {
            return true;
          }
          return false;
        });

        setRows((oldRowList: JSX.Element[]) => {
          const newRowList: JSX.Element[] = [...oldRowList];
          newRowList[offerToEditIndex] = <OfferBoardRow key={offerToEditIndex} offer={offerToEdit} />;
          return newRowList;
        });

        const newOfferList: Offer[] = [...oldOfferList];
        newOfferList[offerToEditIndex] = offerToEdit;
        return newOfferList;
      });
    });

    registrarSocket.on(Events.DELETE_OFFER, (bidderName: string) => {
      setOfferList((oldOfferList: Offer[]) => {
        const offerToDeleteIndex: number = oldOfferList.findIndex((offer: Offer) => {
          if (offer.bidder?.bidderName === bidderName) {
            return true;
          }
          return false;
        });

        setRows((oldRowList: JSX.Element[]) => {
          const newRowList: JSX.Element[] = [...oldRowList];
          newRowList.splice(offerToDeleteIndex, 1);
          return newRowList;
        });

        setOfferNumberOnBoard((oldOfferNumberOnBoard: number) => {
          return oldOfferNumberOnBoard - 1;
        });

        const newOfferList: Offer[] = [...oldOfferList];
        newOfferList.splice(offerToDeleteIndex, 1);
        return newOfferList;
      });
    });

    return () => {
      bidderSocket.off(Events.BID_LOT);
      registrarSocket.off(Events.EDIT_Offer);
      registrarSocket.off(Events.DELETE_OFFER);
    };
  }, []);

  return (
    <>
      <Stack>
        <Stack className={bidGridTableContainer}>
          <table style={{ borderCollapse: 'collapse', tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th
                  style={{
                    width: '17%',
                    height: '20vh'
                  }}
                >
                  <img src={props.lot?.imgUrl} alt="Lot Image" style={{ height: '100%' }} />
                </th>
                <th
                  style={{
                    width: '14%',
                    color: 'white',
                    paddingTop: '1rem',
                    fontSize: '2.5rem',
                    backgroundColor: `rgb(${appSettings.yellowRGBColorForMoneyChoice})`
                  }}
                >
                  <Stack horizontalAlign="center" verticalAlign="center">
                    <MoneyIconSVG className={bidGridTableHeadIcon} />
                    <p style={{ margin: '1rem' }}>金錢 Money</p>
                  </Stack>
                </th>
                <th
                  style={{
                    width: '24%',
                    color: 'white',
                    paddingTop: '1rem',
                    fontSize: '2.5rem',
                    backgroundColor: `rgb(${appSettings.magentaRGBColorForOpportunityChoice})`
                  }}
                >
                  <Stack horizontalAlign="center" verticalAlign="center">
                    <OpportunityIconSVG className={bidGridTableHeadIcon} />
                    <p style={{ margin: '1rem' }}>機會 Opportunity</p>
                  </Stack>
                </th>
                <th
                  style={{
                    width: '24%',
                    color: 'white',
                    paddingTop: '1rem',
                    fontSize: '2.5rem',
                    backgroundColor: `rgb(${appSettings.blueRGBColorForUnderstandingChoice})`
                  }}
                >
                  <Stack horizontalAlign="center" verticalAlign="center">
                    <UnderstandIconSVG className={bidGridTableHeadIcon} />
                    <p style={{ margin: '1rem' }}>理解 Understanding</p>
                  </Stack>
                </th>
                <th
                  style={{
                    width: '24%',
                    color: 'white',
                    paddingTop: '1rem',
                    fontSize: '2.5rem',
                    backgroundColor: `rgb(${appSettings.greenRGBColorForExchangeChoice})`
                  }}
                >
                  <Stack horizontalAlign="center" verticalAlign="center">
                    <ExchangeIconSVG className={bidGridTableHeadIcon} />
                    <p style={{ margin: '1rem' }}>交換 Exchange</p>
                  </Stack>
                </th>
              </tr>
            </thead>
            <tbody className={bidGridTableBody}>{rows}</tbody>
          </table>
        </Stack>
      </Stack>
    </>
  );
};

const bidGridTableContainer = mergeStyles({
  overflow: 'hidden',
  height: '100%',
  // margin: '0.5rem 0.5rem 0 0.5rem',
  selectors: {
    '&>table>thead': {
      borderBottom: '0.1rem solid grey'
    },
    '&>table>thead>tr>th': {
      borderRight: '0.1rem solid grey'
    },
    '&>table>thead>tr>th:last-child': {
      borderRight: 'none'
    }
  }
});

const bidGridTableHeadIcon = mergeStyles({
  width: '4rem',
  filter: 'invert(100%) sepia(5%) saturate(19%) hue-rotate(215deg) brightness(107%) contrast(106%)'
});

const bidGridTableBody = mergeStyles({
  height: '79vh',
  fontWeight: 'bold',
  textAlign: 'left',
  selectors: {
    '&>tr>td': {
      padding: '0.3rem',
      borderRight: '0.1rem solid grey',
      fontSize: '1.8rem'
    },
    '&>tr>td:first-child': {
      fontSize: '2.3rem'
    },
    '&>tr>td:nth-child(2)': {
      textAlign: 'center'
    },
    '&>tr>td:last-child': {
      borderRight: 'none'
    },
    '&>tr': {
      height: '7.9vh',
      padding: '0.3rem 0.6rem',
      borderBottom: '0.1rem solid grey'
    },
    '&>tr:last-child': {
      border: 'none'
    }
  }
});
