import { Stack, Text } from '@fluentui/react';
import { titleTextStyles } from '../../styles/LotCreationDialog/LotCreationDialog.header.styles';

export const LotCreationDialogHeader = (props: { lotCreated: boolean }): JSX.Element => {
  return (
    <Stack tokens={{ childrenGap: '0.5rem' }}>
      <Text styles={titleTextStyles}>{props.lotCreated ? 'Artwork Created' : 'Create An Artwork'}</Text>
    </Stack>
  );
};
