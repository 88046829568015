import { Stack } from '@fluentui/react';
import BidFooter from '../../components/bidder/BidFooter';
import LotWinListScreen from '../../components/bidder/LotWinListScreen';

export default (): JSX.Element => {
  return (
    <Stack>
      <LotWinListScreen />
      <BidFooter />
    </Stack>
  );
};
