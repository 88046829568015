import { Stack } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { authContainer, logoImage } from '../../components/styles/Auth.styles';
import pcaLogoHorizontal from '../../assets/PCA-Landscape-Logo.svg';
import RegistrarSignInScreen from '../../components/registrar/RegistrarSignInScreen';
import RegistrarSignUpScreen from '../../components/registrar/RegistrarSignUpScreen';
import { appSettings } from '../../appSettings';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { RegistrarCookie } from '../../types/registrarCookie';
import { MessageBannerProps } from '../../types/messageBanner';
import { MessageBanner } from '../../components/common/MessageBanner';

export default (): JSX.Element => {
  const [messageBanner, setMessageBanner] = useState<MessageBannerProps | undefined>(undefined);
  const [authMode, setAuthMode] = useState<string>('signin');
  const [cookies] = useCookies([appSettings.registrar.userInfoCookieName]);
  const navigate = useNavigate();

  useEffect(() => {
    const registrarToken = (cookies[appSettings.registrar.userInfoCookieName] as RegistrarCookie)?.token;
    const isAuthenticated: boolean = (registrarToken && registrarToken != 'undefined') as boolean;

    if (isAuthenticated) {
      navigate('/registrar');
    }
  }, []);

  const changeAuthMode = () => {
    setAuthMode(authMode === 'signin' ? 'signup' : 'signin');
  };

  return (
    <>
      {messageBanner && (
        <MessageBanner messageBarType={messageBanner.messageBarType} messageContent={messageBanner.messageContent} />
      )}
      <Stack horizontalAlign="center" verticalAlign="center" className={authContainer}>
        <img className={logoImage} src={pcaLogoHorizontal} alt="Harbourfront Logo" />
        {authMode === 'signin' ? <h1>Sign In</h1> : <h1>Sign Up</h1>}
        {authMode === 'signin' ? (
          <RegistrarSignInScreen changeAuthMode={changeAuthMode} setMessageBanner={setMessageBanner} />
        ) : (
          <RegistrarSignUpScreen changeAuthMode={changeAuthMode} setMessageBanner={setMessageBanner} />
        )}
      </Stack>
    </>
  );
};
