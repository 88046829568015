import { getTheme, mergeStyles, Stack } from '@fluentui/react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';
import { appSettings } from '../../appSettings';
import { ReactComponent as ExchangeIconSVG } from '../../assets/icons/exchange-icon.svg';
import { ReactComponent as MoneyIconSVG } from '../../assets/icons/money-icon.svg';
import { ReactComponent as OpportunityIconSVG } from '../../assets/icons/opportunity-icon.svg';
import { ReactComponent as UnderstandIconSVG } from '../../assets/icons/understanding-icon.svg';
import { BidderCookie } from '../../types/bidderCookie';

const palette = getTheme().palette;

const BidOptions = {
  money: 'money',
  opportunity: 'opportunity',
  understanding: 'understanding',
  exchange: 'exchange'
} as const;

export default (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const winner = searchParams.get('winner');
  const money = searchParams.get(BidOptions.money);
  const opportunity = searchParams.get(BidOptions.opportunity);
  const understanding = searchParams.get(BidOptions.understanding);
  const exchange = searchParams.get(BidOptions.exchange);
  const [cookies] = useCookies([appSettings.bidder.userInfoCookieName]);
  const bidderName = (cookies[appSettings.bidder.userInfoCookieName] as BidderCookie)?.name;
  const isWinner: boolean = winner === bidderName;
  const winnerFirstNameLastName = winner?.split(' ');

  return (
    <>
      <div className={winnerName}>
        {isWinner ? (
          <p>恭喜 Congratulations</p>
        ) : (
          <>
            <p style={{ marginBottom: 0 }}>{winnerFirstNameLastName ? winnerFirstNameLastName[0] : 'Anonymous'}</p>
            <p>{winnerFirstNameLastName ? winnerFirstNameLastName[1] : 'Winner'}</p>
          </>
        )}
      </div>
      <Stack>
        {exchange === 'true' && (
          <div className={iconsContainer}>
            <ExchangeIconSVG
              style={{
                position: 'fixed',
                top: '-1.1rem',
                right: '2.4rem',
                width: '12rem',
                zIndex: -1,
                rotate: '24deg',
                filter: 'invert(32%) sepia(32%) saturate(6591%) hue-rotate(77deg) brightness(93%) contrast(94%)'
              }}
            />
            <ExchangeIconSVG
              style={{
                position: 'fixed',
                top: '2.9rem',
                left: '2.4rem',
                width: '3rem',
                zIndex: -1,
                rotate: '240deg',
                filter: 'invert(32%) sepia(32%) saturate(6591%) hue-rotate(77deg) brightness(93%) contrast(94%)'
              }}
            />
            <ExchangeIconSVG
              style={{
                position: 'fixed',
                top: '10.3rem',
                left: '-9.7rem',
                width: '23rem',
                zIndex: -4,
                rotate: '180deg',
                filter: 'invert(32%) sepia(32%) saturate(6591%) hue-rotate(77deg) brightness(93%) contrast(94%)'
              }}
            />
            <ExchangeIconSVG
              style={{
                position: 'fixed',
                bottom: '1.8rem',
                left: '2.4rem',
                width: '14rem',
                zIndex: -4,
                rotate: '124.1deg',
                filter: 'invert(32%) sepia(32%) saturate(6591%) hue-rotate(77deg) brightness(93%) contrast(94%)'
              }}
            />
            <ExchangeIconSVG
              style={{
                position: 'fixed',
                top: '25.2rem',
                left: '20.4rem',
                width: '6rem',
                zIndex: -1,
                rotate: '84.1deg',
                filter: 'invert(32%) sepia(32%) saturate(6591%) hue-rotate(77deg) brightness(93%) contrast(94%)'
              }}
            />
          </div>
        )}
        {money === 'true' && (
          <div className={iconsContainer}>
            <MoneyIconSVG
              style={{
                position: 'fixed',
                bottom: '-2.4rem',
                right: '-2.9rem',
                width: '15.4rem',
                zIndex: -2,
                filter: 'invert(84%) sepia(16%) saturate(1686%) hue-rotate(351deg) brightness(94%) contrast(95%)'
              }}
            />
            <MoneyIconSVG
              style={{
                position: 'fixed',
                top: '8.1rem',
                left: '32.6rem',
                width: '35.3rem',
                zIndex: -4,
                rotate: '-23.2deg',
                filter: 'invert(84%) sepia(16%) saturate(1686%) hue-rotate(351deg) brightness(94%) contrast(95%)'
              }}
            />
            <MoneyIconSVG
              style={{
                position: 'fixed',
                top: '-14.5rem',
                right: '-13.5rem',
                width: '27.6rem',
                zIndex: -1,
                filter: 'invert(84%) sepia(16%) saturate(1686%) hue-rotate(351deg) brightness(94%) contrast(95%)'
              }}
            />
            <MoneyIconSVG
              style={{
                position: 'fixed',
                top: '3.2rem',
                left: '3.5rem',
                width: '5.2rem',
                zIndex: -1,
                rotate: '19.3deg',
                filter: 'invert(84%) sepia(16%) saturate(1686%) hue-rotate(351deg) brightness(94%) contrast(95%)'
              }}
            />
            <MoneyIconSVG
              style={{
                position: 'fixed',
                top: '18.7rem',
                right: '0.2rem',
                width: '5.2rem',
                zIndex: -1,
                rotate: '19.3deg',
                filter: 'invert(84%) sepia(16%) saturate(1686%) hue-rotate(351deg) brightness(94%) contrast(95%)'
              }}
            />
            <MoneyIconSVG
              style={{
                position: 'fixed',
                bottom: '9.5rem',
                right: '6.1rem',
                width: '5.2rem',
                zIndex: -4,
                rotate: '-20.6deg',
                filter: 'invert(84%) sepia(16%) saturate(1686%) hue-rotate(351deg) brightness(94%) contrast(95%)'
              }}
            />
            <MoneyIconSVG
              style={{
                position: 'fixed',
                bottom: '15.7rem',
                left: '0.7rem',
                width: '10.2rem',
                zIndex: -2,
                rotate: '20.6deg',
                filter: 'invert(84%) sepia(16%) saturate(1686%) hue-rotate(351deg) brightness(94%) contrast(95%)'
              }}
            />
          </div>
        )}
        {opportunity === 'true' && (
          <div className={iconsContainer}>
            <OpportunityIconSVG
              style={{
                position: 'fixed',
                top: '12.6rem',
                right: '-6.5rem',
                width: '20rem',
                zIndex: -4,
                filter: 'invert(16%) sepia(86%) saturate(7458%) hue-rotate(334deg) brightness(101%) contrast(104%)'
              }}
            />
            <OpportunityIconSVG
              style={{
                position: 'fixed',
                bottom: '-6.4rem',
                left: '-8.2rem',
                width: '20rem',
                zIndex: -4,
                filter: 'invert(16%) sepia(86%) saturate(7458%) hue-rotate(334deg) brightness(101%) contrast(104%)'
              }}
            />
            <OpportunityIconSVG
              style={{
                position: 'fixed',
                bottom: '18.2rem',
                right: '17.3rem',
                width: '10rem',
                zIndex: -2,
                filter: 'invert(16%) sepia(86%) saturate(7458%) hue-rotate(334deg) brightness(101%) contrast(104%)'
              }}
            />
            <OpportunityIconSVG
              style={{
                position: 'fixed',
                top: '4rem',
                left: '10.1rem',
                width: '5rem',
                zIndex: -1,
                filter: 'invert(16%) sepia(86%) saturate(7458%) hue-rotate(334deg) brightness(101%) contrast(104%)'
              }}
            />
            <OpportunityIconSVG
              style={{
                position: 'fixed',
                bottom: '4rem',
                right: '1.4rem',
                width: '5rem',
                zIndex: -1,
                filter: 'invert(16%) sepia(86%) saturate(7458%) hue-rotate(334deg) brightness(101%) contrast(104%)'
              }}
            />
          </div>
        )}
        {understanding === 'true' && (
          <div className={iconsContainer}>
            <UnderstandIconSVG
              style={{
                position: 'fixed',
                top: '-5.2rem',
                right: '7.4rem',
                width: '37rem',
                zIndex: -4,
                rotate: '10.6deg',
                filter: 'invert(39%) sepia(38%) saturate(698%) hue-rotate(159deg) brightness(94%) contrast(96%)'
              }}
            />
            <UnderstandIconSVG
              style={{
                position: 'fixed',
                bottom: '6.8rem',
                right: '1.2rem',
                width: '17rem',
                zIndex: -2,
                rotate: '21.9deg',
                filter: 'invert(39%) sepia(38%) saturate(698%) hue-rotate(159deg) brightness(94%) contrast(96%)'
              }}
            />
            <UnderstandIconSVG
              style={{
                position: 'fixed',
                bottom: '3.2rem',
                left: '0.7rem',
                width: '5rem',
                zIndex: -1,
                rotate: '-28.1deg',
                filter: 'invert(39%) sepia(38%) saturate(698%) hue-rotate(159deg) brightness(94%) contrast(96%)'
              }}
            />
            <UnderstandIconSVG
              style={{
                position: 'fixed',
                bottom: '-1rem',
                right: '0.9rem',
                width: '5rem',
                zIndex: -1,
                rotate: '-221.9deg',
                filter: 'invert(39%) sepia(38%) saturate(698%) hue-rotate(159deg) brightness(94%) contrast(96%)'
              }}
            />
          </div>
        )}
      </Stack>
    </>
  );
};

const iconsContainer = mergeStyles({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
});

const winnerName = mergeStyles({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontFamily: 'Harbourfront Body Bold',
  fontSize: '7vw',
  fontWeight: '800',
  color: palette.black,
  textTransform: 'uppercase',
  zIndex: 2
});
