import { getTheme, mergeStyles } from '@fluentui/react';
import { appSettings } from '../../appSettings';

const palette = getTheme().palette;

type LotCardProps = {
  isDefaultStyle?: boolean;
  lotId: string;
  lotIndex: string;
  lotTitle: string;
  lotStatus?: string;
};

export default (props: LotCardProps): JSX.Element => {
  const lotIndexString = `拍品 Lot ${props.lotIndex}`;
  const lotInfoUrl = `${appSettings.host.artAuctionClientHost}/bidder/lots/${props.lotId}`;
  const lotBidUrl = `${appSettings.host.artAuctionClientHost}/bidder/lots/${props.lotId}/bid`;
  return (
    <>
      <a
        href={props.lotStatus === 'bidding' ? lotBidUrl : lotInfoUrl}
        className={props.isDefaultStyle ? defaultCardOuterContainer : cardOuterContainer(props.lotStatus)}
      >
        <div className={props.isDefaultStyle ? defaultCardInnerContainer : cardInnerContainer(props.lotStatus)}>
          <h1 className={lotIndex}>{lotIndexString}</h1>
          <p className={cardTitle}>{props.lotTitle}</p>
        </div>
      </a>
    </>
  );
};

const defaultCardOuterContainer = mergeStyles({
  display: 'flex',
  height: '11.5rem',
  width: '100%',
  position: 'relative',
  padding: '1.25rem',
  margin: '1rem 0',
  boxSizing: 'border-box',
  border: `0.125rem solid ${palette.black}`,
  alignItems: 'flex-end',
  textDecoration: 'none',
  '@media only screen and (max-width: 1028px)': {
    height: '5.675rem',
    padding: '1.0625rem'
  }
});

const cardOuterContainer = (lotStatus?: string) =>
  mergeStyles(defaultCardOuterContainer, {
    border:
      lotStatus === 'bidded'
        ? `0.125rem solid ${palette.neutralQuaternaryAlt}`
        : lotStatus === 'bidding'
        ? `0.125rem solid ${palette.black}`
        : '',
    backgroundColor: lotStatus === 'bidding' ? 'yellow' : 'white',
    boxShadow: lotStatus === 'willBid' ? 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px' : ''
  });

const defaultCardInnerContainer = mergeStyles({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  color: palette.black,
  boxSizing: 'border-box',
  padding: '1.4rem',
  lineHeight: '3.2rem',
  '@media only screen and (max-width: 1028px)': {
    padding: 0,
    lineHeight: '1.8rem'
  }
});

const cardInnerContainer = (lotStatus?: string) =>
  mergeStyles(defaultCardInnerContainer, {
    color: lotStatus === 'bidded' ? palette.neutralQuaternaryAlt : palette.black,
    backgroundColor: lotStatus === 'bidding' ? 'yellow' : 'white'
  });

const lotIndex = mergeStyles({
  fontFamily: 'Harbourfront Header',
  fontSize: '2.4rem',
  margin: 0,
  '@media only screen and (max-width: 1028px)': {
    fontSize: '1.5rem'
  }
});

const cardTitle = mergeStyles({
  fontSize: '1.2rem',
  textAlign: 'left',
  margin: 0,
  overflow: 'hidden',
  '@media only screen and (max-width: 1028px)': {
    fontSize: '0.8rem'
  }
});
