import { ITextStyles } from '@fluentui/react';

const titleTextStyles: ITextStyles = {
  root: {
    fontSize: '1.5rem',
    fontWeight: 600
  }
};

export { titleTextStyles };
